import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import {
	Helmet,
	Header,
	Content,
	Footer,
} from './Components';

import './Layout.scss';

const Layout = ({ className, hasNavigation = true, children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
				siteMetadata {
					title
				}
				}
			}
		`}
		render={data => (
			<main className={classNames('layout', className)}>
				<Helmet siteMetadata={data.site.siteMetadata}>
					<html lang="en" />
				</Helmet>
				<Header hasNavigation={hasNavigation} />
				<Content>
					{children}
				</Content>
				<Footer hasNavigation={hasNavigation} />
			</main>
		)}
	/>
);

export default Layout;
