import React from 'react';

import { Icon, IconSizes } from '../../../Common/Components';

import './SubscribePopupCloseButton.scss';

const SubscribePopupCloseButton = ({ onClick }) => (
	<button onClick={onClick} className="subscribe-popup__close-button">
		<Icon name="close" size={IconSizes.lg} />
	</button>
);

export default SubscribePopupCloseButton;
