import React from 'react';

import { Container, Grid, Card } from '../../../Common/Components';

import './_DetailsCards.scss';

const detailsCardsData = [
	[
		{
			title: 'Shipment date',
			text: 'Date (and time if possible) on which the shipment took place (originated)',
		},
		{
			title: 'Shipment ID',
			text: 'Unique ID of the shipment',
		},
	],
	[
		{
			title: 'Origin',
			text: 'Origin information including country, state, city, zip, and site',
		},
		{
			title: 'Destination',
			text: 'Destination information including country, state, city, zip, and site',
		},
	],
	[
		{
			title: 'Destination',
			text: 'Used equipment (53 dry van, 53 reefer, flatbed, bulk, etc.)',
		},
		{
			title: 'Equipment class',
			text: 'Class of equipment used for shipment (dry van trailer, refrigerated trailer, etc.)',
		},
	],
	[
		{
			title: 'Movement type',
			text: 'E.g. inbound from supplier/ intra-company / outbound to customer',
		},
		{
			title: 'Number of stops',
			text: 'Number of stops on multi-stop routes (0 for direct origin-destination line haul)',
		},
	],
	[
		{
			title: 'Temperature control',
			text: 'Temperature control type (none / cooled / refrigerated / frozen / etc.)',
		},
		{
			title: 'Hazardous goods',
			text: 'Hazardous goods shipment (True / False)',
		},
	],
	[
		{
			title: 'In-house fleet',
			text: 'In-house fleet used for shipment (True / False)',
		},
		{
			title: 'Carrier',
			text: 'Carrer SCAC code',
		},
	],
	[
		{
			title: 'Transporation mode',
			text: 'Transportation mode (over the road truckload / over the road less than truckload / intermodal/ rail / air / ocean)',
		},
		{
			title: 'Linehaul spend',
			text: 'Linehaul costs of shipment, can also be RPM if distance is provided',
		},
	],
	[
		{
			title: 'Spend currency',
			text: 'Currency of the provided costs',
		},
		{
			title: 'Shipment mileage',
			text: 'Shipment mileage (in case of intermodal only over the road part)',
		},
	],
	[
		{
			title: 'Fuel surcharge',
			text: 'Total fuel surcharge (provided in stated currency)',
		},
		{
			title: 'Net weight',
			text: 'Shipment weight (net)',
		},
	],
];

const DetailsCards = ({ className }) => (
	<Container className={`${className} details-cards__wave-background`} isCentered hasMaxWidth hasPadding>
		<div>
		{
			detailsCardsData.map(row => (
				<Grid textAlign="left" isMaxWidth>
					{
						row.map(({ title, text }) => {
							return (
								<Grid.Column mobile={12} tablet={12} computer={6} className="details-cards__card">
									<Card as='section'>
										<h2 className="details-cards__title">{title}</h2>
										<p className="other-pages__body-text details-cards__text">{text}</p>
									</Card>
								</Grid.Column>
							)
						}
						)
					}
				</Grid>

			))
		}
		</div>
	</Container>
);

export default DetailsCards;
