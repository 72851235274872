import React from 'react';

import { provideDeviceType } from '../../Common/Components';
import { Layout } from '../../Features';
import { Hero, Content } from './Components';

import './SingleCareer.scss';

const SingleCareerTemplate = ({ pageContext: { career } }) => (
	<Layout className="single-career-template">
		<Hero title={career.title} applyLink={career.applyLink} />
		<Content career={career} />
	</Layout>
);

export default provideDeviceType(SingleCareerTemplate);
