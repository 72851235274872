import React from 'react';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';
import { api } from '../../../Common/Services';
import { resources } from '../../../Common/Constants';
import createNewContact from '../_contactBuilder';
import requestTypes from '../_requestTypes';
import { locations } from '../../../Common/Constants';

import { Container, Button, Responsive, validators, Grid, Checkbox, FormSuccessMessage } from '../../../Common/Components';

import './_ContactForm.scss';

const contactFormInitialValues = {
	doSubscribeToEmail: true,
};

const leafPhone = {
	areaCode: '332',
	number: '240-4553'
}

const validateContactFormFields = (values) => {
	if (!values.firstName || !values.lastName || !values.phone || !values.email) {
		return {
			FORM_ERROR: 'Please enter the required fields.',
		};
	}

	const emailErrorMessage = validators.email(values.email);
	if (emailErrorMessage) {
		return {
			FORM_ERROR: emailErrorMessage,
		}
	}

	return undefined;
};

class ContactForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subscribeSuccessMessage: undefined,
			subscribeErrorMessage: undefined,
			isFormDisabled: false,
			isFormSuccess: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values) {
		this.setState({
			isFormDisabled: true,
		});
		const contact = createNewContact({ data: values, requestType: requestTypes.contact });

		api.post(resources.contactRequests, contact)
			.then(response => {
				this.setState({
					subscribeSuccessMessage: `Thanks for getting in touch, ${response.data.firstName}. We will contact you shortly.`,
					isFormSuccess: true,
				});
			})
			.catch(error => {
				this.setState({
					subscribeErrorMessage: error.message,
					isFormDisabled: false,
				});
			});
	}

	render() {
		const { subscribeSuccessMessage, subscribeErrorMessage, isFormDisabled, isFormSuccess } = this.state;

		return (
			<Container className="contact-form-wrapper" as="section" hasPadding hasMaxWidth>
				{!isFormSuccess && <Form
					onSubmit={this.onSubmit}
					initialValues={contactFormInitialValues}
					validate={validateContactFormFields}
					render={({ handleSubmit, submitFailed, submitSucceeded, errors }) => (
						<div>
							<h3 className="other-pages__body-text contact-form__title">
								Interested in knowing more? <Responsive mobile="br" tablet="br" computer="br" />
								Provide your details below and we will get in touch with you.
							</h3>
							<form className="contact-form" onSubmit={handleSubmit}>
								<div className="contact-form__row">
									<Grid>
										<Grid.Column width={12} className="contact-form__form-message-wrapper">
											<div className="contact-form__form-error">
												{(submitFailed && errors.FORM_ERROR)|| (!submitFailed && submitSucceeded && !subscribeSuccessMessage && subscribeErrorMessage)}
											</div>
										</Grid.Column>
										<Field
											name="firstName"
											validate={validators.required}
											render={({ input, meta }) => (
												<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
													<input
														className={classNames('contact-form__input', {
															'contact-form__input--error': meta.error && meta.touched,
														})}
														type="text"
														placeholder="First name"
														disabled={isFormDisabled}
														{...input}
													/>
												</Grid.Column>
											)}
										/>
										<Field
											name="lastName"
											validate={validators.required}
											render={({ input, meta }) => (
												<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
													<input
														className={classNames('contact-form__input', {
															'contact-form__input--error': meta.error && meta.touched,
														})}
														type="text"
														placeholder="Last name"
														disabled={isFormDisabled}
														{...input}
													/>
												</Grid.Column>
											)}
										/>
										<Field
											name="email"
											validate={validators.combineValidators(validators.required, validators.email)}
											render={({ input, meta }) => (
												<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
													<input
														className={classNames('contact-form__input', {
															'contact-form__input--error': meta.error && meta.touched,
														})}
														type="email"
														placeholder="Work email"
														disabled={isFormDisabled}
														{...input}
													/>
												</Grid.Column>
											)}
										/>
										<Field
											name="phone"
											validate={validators.required}
											render={({ input, meta }) => (
												<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
													<input
														className={classNames('contact-form__input', {
															'contact-form__input--error': meta.error && meta.touched,
														})}
														type="text"
														placeholder="Phone"
														disabled={isFormDisabled}
														{...input}
													/>
												</Grid.Column>
											)}
										/>
										<Field
											name="message"
											render={({ input, meta }) => (
												<Grid.Column className="contact-form__form-field" width={12}>
											<textarea
												className={classNames('contact-form__textarea', {
													'contact-form__input--error': meta.error && meta.touched,
												})}
												type="text"
												placeholder="Message (optional)"
												disabled={isFormDisabled}
												{...input}
											/>
												</Grid.Column>
											)}
										/>
										<Field
											name="doSubscribeToEmail"
											type="checkbox"
											render={({ input }) => (
												<Grid.Column className="contact-form__form-field" width={12}>
													<Checkbox
														className="contact-form__checkbox"
														label="Yes, I want to be informed about relevant Leaf news occasionally."
														disabled={isFormDisabled}
														{...input}
													/>
												</Grid.Column>
											)}
										/>
										<Grid.Column className="contact-form__buttons" width={12} textAlign="center">
											<Button
												type="submit"
												isHighlighted
												hasLargePadding
												disabled={isFormDisabled}
											>
												Send message
											</Button>
										</Grid.Column>
									</Grid>
								</div>
							</form>
						</div>
					)}
				/>}
				{!isFormSuccess &&
				<div className="contact-form__footer">
					<div className="contact-form__footer-text contact-form__footer-text--emphasized">Phone</div>
					<a href={`tel:${leafPhone.areaCode}-${leafPhone.number}`} className="contact-form__footer-text contact-form__footer-phone">{`(${leafPhone.areaCode}) ${leafPhone.number}`}</a>
					<div className="contact-form__footer-text contact-form__footer-text--emphasized contact-form__footer-address-label">Address</div>
					<address className="contact-form__footer-text contact-form__footer-address">
						305 West Broadway Suite 147<br />
						New York, NY 10013
					</address>
				</div>
					}
				{ isFormSuccess && <FormSuccessMessage message={subscribeSuccessMessage} backToLocation={locations.index} /> }
				</Container>
		);
	}
}

export default ContactForm;
