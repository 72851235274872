import React from 'react';

import { locations } from '../../../Common/Constants';
import { Container, BackButton, Responsive } from '../../../Common/Components';
import DetailsContentTitle from './_DetailsContentTitle';
import DetailsCards from './_DetailsCards';
import DetailsFooter from './_DetailsFooter';

import './_DetailsContent.scss';
import QuestionMarkTitle from "./_QuestionMarkTitle";

const DetailsContent = () => (
	<div>
		<Container as="section" className="details-content">
			<Container className="details-content__header" isCentered>
				<BackButton text='Back to free assessment page' to={locations.businessChief} />
			</Container>
			<DetailsContentTitle title='We can work with any data in any format'>
				To make things easier for you, you can provide us data in your <Responsive tablet="br" /> preferred format.
				For best results, we <Responsive computer="br" /> recommend to you to share <Responsive tablet="br" /> shipment-level data as indicated below.
			</DetailsContentTitle>
			<Container hasPadding hasMaxWidth>
				<QuestionMarkTitle>What kind of information should I provide for the <Responsive tablet="br" /> best results?</QuestionMarkTitle>
			</Container>
			<DetailsCards className="details-content__cards" />
			<DetailsFooter />
			<Container className="details-content__footer" isCentered>
				<BackButton text='Back to free assessment page' to={locations.businessChief} />
			</Container>
		</Container>
	</div>
);

export default DetailsContent;
