import React from 'react';
import classNames from 'classnames';

const ReduceAdministrationSvg = ({ className }) => (
    <svg className={classNames(className)} width="81px" height="80px" viewBox="0 0 81 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-30.000000, -622.000000)" id="Group-16">
                <g transform="translate(30.000000, 622.000000)">
                    <path d="M53.6209794,0 C38.8658937,0.0109234337 26.9230769,11.9597172 26.9230769,26.6985207 L26.9230769,53.4153846 L53.6401469,53.4153846 C68.3696759,53.3857059 80.3079584,41.4474233 80.3184696,26.7174822 C80.3184696,26.7132227 80.3185383,17.8073953 80.3186757,0 L53.6209794,0 Z" id="Fill-1" fill="#92BCEE" opacity="0.300000012"></path>
                    <g id="archive" fill="#FFFFFF" fillRule="nonzero">
                        <path d="M79.688716,74.2866926 L66.4073152,1.23859922 C66.2745525,0.574474708 65.4776654,0.0432684825 64.8135409,0.176031128 L45.1189105,3.81400778 C44.9861479,3.21385214 44.5033463,2.83237354 43.8287938,2.83237354 L22.5785214,2.83237354 L1.32809339,2.83237354 C0.531206226,2.83237354 0,3.36373541 0,4.16062257 L0,78.5368093 C0,79.3336965 0.531206226,79.8649027 1.32809339,79.8649027 L22.5783658,79.8649027 L43.8287938,79.8649027 C44.6256809,79.8649027 45.1568872,79.3336965 45.1568872,78.5368093 L45.1568872,18.3375875 L55.7821012,78.8023346 C55.7821012,79.0680156 56.0477821,79.3335409 56.3133074,79.5992218 C56.5789883,79.7319844 56.8445136,79.8649027 57.1101946,79.8649027 C57.2429572,79.8649027 57.2429572,79.8649027 57.3758755,79.8649027 L78.6261479,75.8804669 C79.2902724,75.7475486 79.8214786,74.9506615 79.688716,74.2866926 Z M2.65634241,5.48871595 L21.250428,5.48871595 L21.250428,77.2085603 L2.65634241,77.2085603 L2.65634241,5.48871595 Z M42.5007004,77.2085603 L23.9066148,77.2085603 L23.9066148,5.48871595 L42.5007004,5.48871595 L42.5007004,77.2085603 Z M58.1727626,76.9428794 L45.688249,6.28560311 L64.0166537,3.09805447 L76.7668482,73.4898054 L58.1727626,76.9428794 Z" id="Shape"></path>
                        <path d="M17.2658366,12.1294942 L6.64077821,12.1294942 C5.84389105,12.1294942 5.31268482,12.6607004 5.31268482,13.4575875 L5.31268482,34.7078599 C5.31268482,35.5047471 5.84389105,36.0359533 6.64077821,36.0359533 L17.2659922,36.0359533 C18.0628794,36.0359533 18.5940856,35.5047471 18.5940856,34.7078599 L18.5940856,13.4575875 C18.5940856,12.6607004 18.0627237,12.1294942 17.2658366,12.1294942 Z M7.9688716,20.0983658 L15.9377432,20.0983658 L15.9377432,22.7547082 L7.9688716,22.7547082 L7.9688716,20.0983658 Z M7.9688716,25.4108949 L15.9377432,25.4108949 L15.9377432,28.0672374 L7.9688716,28.0672374 L7.9688716,25.4108949 Z M15.9377432,14.7856809 L15.9377432,17.4420233 L7.9688716,17.4420233 L7.9688716,14.7856809 L15.9377432,14.7856809 Z M7.9688716,33.3797665 L7.9688716,30.7234241 L15.9377432,30.7234241 L15.9377432,33.3797665 L7.9688716,33.3797665 Z" id="Shape"></path>
                        <path d="M11.9533074,61.2708171 C8.23455253,61.2708171 5.31252918,64.1926848 5.31252918,67.9115953 C5.31252918,71.6305058 8.23439689,74.5523735 11.9533074,74.5523735 C15.6720623,74.5523735 18.5940856,71.6305058 18.5940856,67.9115953 C18.5940856,64.1926848 15.6720623,61.2708171 11.9533074,61.2708171 Z M11.9533074,71.8960311 C9.69540856,71.8960311 7.9688716,70.1694942 7.9688716,67.9115953 C7.9688716,65.6536965 9.69540856,63.9271595 11.9533074,63.9271595 C14.2112062,63.9271595 15.9377432,65.6536965 15.9377432,67.9115953 C15.9377432,70.1693385 14.2112062,71.8960311 11.9533074,71.8960311 Z" id="Shape"></path>
                        <path d="M27.8910506,36.0361089 L38.5162646,36.0361089 C39.3131518,36.0361089 39.844358,35.5049027 39.844358,34.7080156 L39.844358,13.4575875 C39.844358,12.6607004 39.3131518,12.1294942 38.5162646,12.1294942 L27.8910506,12.1294942 C27.0941634,12.1294942 26.5629572,12.6607004 26.5629572,13.4575875 L26.5629572,34.7078599 C26.5629572,35.5047471 27.0941634,36.0361089 27.8910506,36.0361089 Z M37.1880156,28.0672374 L29.219144,28.0672374 L29.219144,25.4108949 L37.1880156,25.4108949 L37.1880156,28.0672374 Z M37.1880156,22.7545525 L29.219144,22.7545525 L29.219144,20.0982101 L37.1880156,20.0982101 L37.1880156,22.7545525 Z M29.219144,33.3797665 L29.219144,30.7234241 L37.1880156,30.7234241 L37.1880156,33.3797665 L29.219144,33.3797665 Z M37.1880156,14.7856809 L37.1880156,17.4420233 L29.219144,17.4420233 L29.219144,14.7856809 L37.1880156,14.7856809 Z" id="Shape"></path>
                        <path d="M33.2035798,74.5522179 C36.9223346,74.5522179 39.844358,71.6303502 39.844358,67.9114397 C39.844358,64.1925292 36.9224903,61.2706615 33.2035798,61.2706615 C29.4848249,61.2706615 26.5628016,64.1925292 26.5628016,67.9114397 C26.5628016,71.6303502 29.4848249,74.5522179 33.2035798,74.5522179 Z M33.2035798,63.9271595 C35.4614786,63.9271595 37.1880156,65.6536965 37.1880156,67.9115953 C37.1880156,70.1694942 35.4614786,71.8960311 33.2035798,71.8960311 C30.9456809,71.8960311 29.219144,70.1694942 29.219144,67.9115953 C29.219144,65.6536965 30.9458366,63.9271595 33.2035798,63.9271595 Z" id="Shape"></path>
                        <path d="M63.7634241,16.8462257 C63.7750973,16.7336965 63.7727626,16.6211673 63.7509728,16.5123735 C63.7330739,16.4227237 63.7021012,16.3358755 63.6619455,16.2522957 L62.8211673,11.332607 C62.6884047,10.6684825 61.8915175,10.1372763 61.227393,10.2700389 L50.7350973,12.1294942 C50.4694163,12.1294942 50.2038911,12.5279377 49.9382101,12.7936187 C49.6725292,13.0592996 49.6725292,13.3248249 49.6725292,13.7232685 L51.51393,24.0479377 C51.5161089,24.1044358 51.5209339,24.1604669 51.5319844,24.2155642 C51.5421012,24.2663035 51.5553307,24.3148638 51.5699611,24.3624903 L52.444358,29.2649027 C52.4474708,29.3087938 52.4530739,29.3523735 52.4616342,29.3953307 C52.4678599,29.4266148 52.4757977,29.4566537 52.4838911,29.4866926 L53.3914397,34.5750973 C53.5242023,35.2392218 54.0555642,35.6376654 54.7195331,35.6376654 C54.7195331,35.6376654 54.8522957,35.6376654 54.8522957,35.770428 L65.3445914,33.9109728 C66.008716,33.7782101 66.5399222,32.981323 66.4071595,32.3171984 L65.5891051,27.530428 C65.63393,27.3553307 65.644358,27.1757198 65.6102724,27.0046693 C65.5803891,26.8550973 65.5154864,26.7129961 65.4272374,26.583035 L64.6837354,22.2325292 C64.7059922,22.0955642 64.7070817,21.9575097 64.6804669,21.8249027 C64.6580545,21.7128405 64.6154086,21.6052918 64.5590661,21.5033463 L63.7634241,16.8462257 Z M61.8,20.9905058 L53.9614008,22.3796109 L53.501323,19.7343191 L61.3431907,18.3445914 L61.8,20.9905058 Z M54.4057588,24.9346304 L62.2578988,23.6424903 L62.6954086,26.1761868 L54.863035,27.5642023 L54.4057588,24.9346304 Z M60.4306615,13.059144 L60.8824903,15.6757977 L53.0373541,17.0659922 L52.5945525,14.5201556 L60.4306615,13.059144 Z M55.7821012,32.8485603 L55.3271595,30.2325292 L63.1562646,28.8451362 L63.6182101,31.5204669 L55.7821012,32.8485603 Z" id="Shape"></path>
                        <path d="M64.6806226,59.6770428 C60.9618677,60.3411673 58.5712062,63.7942412 59.2351751,67.3802335 C59.7663813,70.5677821 62.5554864,72.8256809 65.743035,72.8256809 C66.008716,72.8256809 66.4071595,72.6929183 67.0711284,72.6929183 C68.7976654,72.2944747 70.258677,71.3648249 71.3212451,69.9038132 C72.2508949,68.4428016 72.6493385,66.7162646 72.3838132,64.9897276 C71.9853696,63.2631907 71.0557198,61.802179 69.5947082,60.7396109 C68.1338521,59.8098054 66.4073152,59.4113619 64.6806226,59.6770428 Z M68.1338521,62.8645914 C68.9307393,63.3957977 69.5948638,64.3256031 69.7276265,65.3880156 C69.8603891,66.3176654 69.7276265,67.3802335 69.0635019,68.3098833 C68.5322957,69.1067704 67.6024903,69.7708949 66.5400778,69.9036576 C64.282179,70.3021012 62.2899611,68.8410895 61.8915175,66.7161089 C61.7587549,65.7864591 61.8915175,64.7238911 62.555642,63.7942412 C63.0868482,62.9973541 64.0166537,62.3332296 65.0790661,62.2004669 C65.2118288,62.2004669 65.4775097,62.2004669 65.7431907,62.2004669 C66.5400778,62.2004669 67.336965,62.3333852 68.1338521,62.8645914 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default ReduceAdministrationSvg;
