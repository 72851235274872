import React from 'react';

import { locations } from '../../../../../../Common/Constants';
import { externalLinks } from '../../../../../../Common/Config';
import {
	Icon,
	IconSizes,
	ExternalLink,
	InternalLink,
} from '../../../../../../Common/Components';
import { FooterMenuItem } from '../';

const FooterMenuCompany = ({ as: As, ...props }) => (
	<As name="Company" {...props}>
		<FooterMenuItem>
			<InternalLink to={locations.about}>About us</InternalLink>
		</FooterMenuItem>
		<FooterMenuItem>
			<InternalLink to={locations.careers}>Careers</InternalLink>
		</FooterMenuItem>
		<FooterMenuItem>
			<ExternalLink to={externalLinks.blog}>
				Blog <Icon name="new-tab" size={IconSizes.base} isVerticallyAlignedMiddle />
			</ExternalLink>
		</FooterMenuItem>
		<FooterMenuItem>
			<InternalLink to={locations.contact}>Contact</InternalLink>
		</FooterMenuItem>
	</As>
);

export default FooterMenuCompany;