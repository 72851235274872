import React from 'react';

import { Container, Grid, Icons, WaveSeparator, withDeviceType } from '../../../Common/Components';
import Benefit from './_Benefit';
import Video from '../../landing/Components/_Video';

import './_Benefits.scss';

export const iFrameHeights = {
	mobile: '170',
	tablet: '356',
	desktop: '690'
};

const getiFrameHeight = (deviceType) => {
	if (deviceType.isMobile) {
		return iFrameHeights.mobile;
	}

	if (deviceType.isTablet) {
		return iFrameHeights.tablet;
	}

	return iFrameHeights.desktop;
};

const renderVideo = deviceType => <Video iframe={getiFrame(deviceType)} />;
const getiFrame = deviceType => `<iframe src="https://www.youtube-nocookie.com/embed/7QHIPAXst7c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

const Benefits = ({ deviceType }) => (
	<Container as="section" className="why-leaf-page__benefits">
		{deviceType.isMobile && renderVideo(deviceType)}
		<Container hasMaxWidth hasPadding>
			{!deviceType.isMobile && renderVideo(deviceType)}
			<Grid>
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.RatesSvg}
						title="Guarantee capacity and rates"
						text="Lock in future rates and capacity through Leaf Flex forward contracts so you can protect your margins and manage through market uncertainty."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.ServiceSvg}
						title="Provide reliable service"
						text="Deliver unprecedented service levels and smooth your logistics operations. Leaf Flex binding contracts ensure shipments arrive to your customers as planned."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.DataDrivenSvg}
						title="Make data-driven decisions"
						text="Stop guessing future market developments based on lagging indicators. Leaf uses advanced analytics to recommend your best possible contracting decisions."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.RefocusResourcesSvg}
						title="Tap into network efficiency"
						text="Join the Leaf Network to fill backhauls with efficient circuits that reduce empty miles, saving shippers money while keeping carriers’ trucks full and moving so they earn more."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.SimplifyPaymentSvg}
						title="Reduce your carbon footprint"
						text="The Leaf Network and Flex contracts work together to eliminate empty miles and reduce greenhouse gas emissions from the 30% of trucks that drive empty on the road today."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
				<Grid.Column mobile={12} tablet={12} computer={3}>
					<Benefit
						icon={Icons.ReduceAdministrationSvg}
						title="Refocus resources to strategic activities"
						text="Contracting with Leaf is as simple as placing an order and accepting matches; contract execution and settlement are taken care of automatically."
					/>
				</Grid.Column>
				<Grid.Column only="computer" width={1} />
			</Grid>
		</Container>
		<WaveSeparator className="why-leaf-page__benefits-wave-separator" />
	</Container>
);

export default withDeviceType(Benefits);
