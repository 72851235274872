import React from 'react';

import { Container, Grid } from '../../../../../../Common/Components';
import {
	FooterMenuWhyLeaf,
	FooterMenuFreeAssessments,
	FooterMenuCompany,
	FooterMenuLegal,
	FooterLogo,
	FooterSocial,
	FooterCopyright,
	FooterMenuCollapsible } from '../../Components';

import './FooterMobile.scss';

class FooterMobile extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			openedSubmenu: null,
		};

		this.handleToggleSubmenuClick = this.handleToggleSubmenuClick.bind(this);
	}

	handleToggleSubmenuClick(submenuName) {
		const newOpenedSubmenu = this.state.openedSubmenu !== submenuName ? submenuName : null;

		this.setState({
			openedSubmenu: newOpenedSubmenu,
		});
	}

	render() {
		const { openedSubmenu } = this.state;

		return (
			<div className="footer-mobile">
				<Container as="section" hasPadding>
					<Grid className="footer-mobile__top-part">
						<Grid.Row>
							<Grid.Column textAlign="center">
								<FooterLogo />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<FooterMenuWhyLeaf
									as={FooterMenuCollapsible}
									openedSubmenu={openedSubmenu}
									handleToggleSubmenuClick={this.handleToggleSubmenuClick}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<FooterMenuFreeAssessments
									as={FooterMenuCollapsible}
									openedSubmenu={openedSubmenu}
									handleToggleSubmenuClick={this.handleToggleSubmenuClick}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<FooterMenuCompany
									as={FooterMenuCollapsible}
									openedSubmenu={openedSubmenu}
									handleToggleSubmenuClick={this.handleToggleSubmenuClick}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<FooterMenuLegal
									as={FooterMenuCollapsible}
									openedSubmenu={openedSubmenu}
									handleToggleSubmenuClick={this.handleToggleSubmenuClick}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
				<Container as="section" hasPadding>
					<div className="footer-mobile__fixed-row">
						<FooterSocial />
					</div>
				</Container>
				<Container as="section" hasPadding>
					<div className="footer-mobile__fixed-row">
						<FooterCopyright />
					</div>
				</Container>
			</div>
		);
	}
}

export default FooterMobile;
