import React from 'react';

import { Container, BackButton } from '../../../Common/Components';
import { locations } from '../../../Common/Constants';
import AboutCompany from './AboutCompany';
import Segment from './Segment';
import Text from './Text';
import List from './List';
import ApplyButton from './ApplyButton';

import './Content.scss';

const Content = ({ career }) => (
	<div className="single-career-template__content-wrapper">
		<Container as="section" className="single-career-template__content" hasPadding hasMaxWidth>
			<Container as="article" className="single-career-template__content-header">
				<BackButton text='Back to careers page' to={locations.careers} />
			</Container>
			<AboutCompany />
			<Segment title={ "What does the " + career.titletext + " do?"}>
				<p className="other-pages__body-text single-career-template__text" dangerouslySetInnerHTML={{ __html: career.summary }}/>
			</Segment>
			{ career.whoFits &&
			<Segment title={ "Who makes a great " + career.titletext + "?"}>
				<p className="other-pages__body-text single-career-template__text" dangerouslySetInnerHTML={{ __html: career.whoFits }}/>
			</Segment>
			}
			{ career.whoNot &&
			<Segment title={ "Who doesn't?"}>
				<p className="other-pages__body-text single-career-template__text" dangerouslySetInnerHTML={{ __html: career.whoNot }}/>
			</Segment>
			}
			{ career.responsibilities &&
			<Segment title="Responsibilites">
				<List items={career.responsibilities.split('\\n')} />
			</Segment>
			}
			{ career.qualifications &&
			<Segment title="Qualifications">
				<List items={career.qualifications.split('\\n')} />
			</Segment>
			}
			<Segment title="Compensation">
				<Text>
				Leaf offers a very comprehensive compensation package including competitive base salary, variable compensation and health benefits.
				</Text>
				<Text>
				Leaf Logistics is an equal-opportunity employer and we welcome applicants from all backgrounds. If you’re a passionate 
				team player who wants to have an outsized impact on a diverse and dynamic team, we’d love to hear from you!
				</Text>
			</Segment>
			<Container as="article" className="single-career-template__content-footer" isCentered>
				<ApplyButton link={career.applyLink} />
				<BackButton text='Back to careers page' to={locations.careers} />
			</Container>
		</Container>
	</div>
);

export default Content;
