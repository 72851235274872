import React from 'react';
import classNames from 'classnames';

import settings from '../../../../../../../Common/Config';
import { platformLocations, locations } from '../../../../../../../Common/Constants';
import { InternalLink, ExternalLink, Button, withDeviceType } from '../../../../../../../Common/Components';
import { SubmenuCompany } from './Submenus';
import NavMenuItem from './NavMenuItem';

const NavMenuContent = ({
	submenuComponent: SubmenuComponent,
	openedSubmenu,
	handleToggleSubmenuClick,
	deviceType,
}) => (
	<div className={classNames('nav-menu__content', { 'nav-menu__content--desktop': deviceType.isDesktop })}>
		<div>
			<NavMenuItem>
				<InternalLink to={locations.whyLeaf}>Why Leaf</InternalLink>
			</NavMenuItem>
			<NavMenuItem>
				<InternalLink to={locations.freeOpportunityAssessment}>Get Started</InternalLink>
			</NavMenuItem>
			<NavMenuItem>
				<SubmenuCompany
					name="Company"
					as={SubmenuComponent}
					openedSubmenu={openedSubmenu}
					handleToggleSubmenuClick={handleToggleSubmenuClick}
				/>
			</NavMenuItem>
			<NavMenuItem>
				<ExternalLink to="https://members.leaflogistics.com/" doOpenInSameTab>Members Meeting</ExternalLink>
			</NavMenuItem>
		</div>
		<div>
			<NavMenuItem>
				<ExternalLink to={`${settings.platformUrl}${platformLocations.login}`}>
					<Button isSmall className="nav-menu__login-button">Login</Button>
				</ExternalLink>
			</NavMenuItem>
		</div>
	</div>
);

export default withDeviceType(NavMenuContent);
