import React from 'react';

import { Container, GreenDotHeading, Grid, ExternalLink } from '../../../Common/Components';
import { externalLinks } from '../../../Common/Config';

import './_InNews.scss';

const InNews = () => (
	<Container as="section" className="in-news" hasPadding hasMaxWidth>
		<GreenDotHeading as="h2" className="landing-page__h2 in-news__title" isCentered>
			Leaf in the news
		</GreenDotHeading>
		<Grid className="in-news__logos" padded textAlign="center" verticalAlign="middle">
			<Grid.Column mobile={6} tablet={4} computer={2}>
				<ExternalLink className="in-news__logo" to={externalLinks.spendMatters}>
					<img
						className="in-news__spendmatters-logo"
						src="/images/logos/logo_spendmatters.png"
						alt="spendmatters logo"
					/>
				</ExternalLink>
			</Grid.Column>
			<Grid.Column mobile={6} tablet={4} computer={2}>
				<ExternalLink className="in-news__logo" to={externalLinks.supplyChain}>
					<img
						className="in-news__supply-chain-logo"
						src="/images/logos/logo_scmr.png"
						alt="supply chain logo"
					/>
				</ExternalLink>
			</Grid.Column>
			<Grid.Column mobile={12} tablet={4} computer={2}>
				<ExternalLink className="in-news__logo" to={externalLinks.americanShipper}>
					<img
						className="in-news__american-shipper-logo"
						src="/images/logos/logo_american_shipper.png"
						alt="american shipper logo"
					/>
				</ExternalLink>
			</Grid.Column>
			<Grid.Column mobile={6} tablet={6} computer={2}>
				<ExternalLink className="in-news__logo" to={externalLinks.freightWaves}>
					<img
						className="in-news__freightwave-logo"
						src="/images/logos/logo_freightwave.png"
						alt="freightwave logo"
					/>
				</ExternalLink>
			</Grid.Column>
			<Grid.Column mobile={6} tablet={6} computer={2}>
				<ExternalLink className="in-news__logo" to={externalLinks.joc}>
					<img
						className="in-news__joc-logo"
						src="/images/logos/logo_joc.png"
						alt="joc logo"
					/>
				</ExternalLink>
			</Grid.Column>
		</Grid>
	</Container>
);

export default InNews;
