import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';

import { Icon, IconSizes } from '../../../../../../../Common/Components';

import './NavMenuDesktopSubmenu.scss';

const NavMenuDesktopSubmenu = ({ name, children }) => (
	<div className="nav-menu-desktop__submenu">
		<Menu>
			<Dropdown
				text={name}
				icon={<Icon className="icon" name="chevron-down" size={IconSizes.xs} />}
				basic
				inline
				item
				simple
			>
				<Dropdown.Menu>
					{children}
				</Dropdown.Menu>
			</Dropdown>
		</Menu>
	</div>
);

export default NavMenuDesktopSubmenu;
