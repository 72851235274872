import React from 'react';

import './FooterMenu.scss';

const FooterMenuItem = ({ children }) => (
	<li className="footer-menu__item">
		{children}
	</li>
);

export default FooterMenuItem;
