import React from 'react';
import { Responsive } from 'semantic-ui-react';

import { DeviceTypeContext } from '../Contexts';

const isMobile = width =>
	width <= Responsive.onlyMobile.maxWidth;

const isTablet = width =>
	width >= Responsive.onlyTablet.minWidth &&
	width <= Responsive.onlyTablet.maxWidth;

const isDesktop = width =>
	width >= Responsive.onlyComputer.minWidth;

export default WrappedComponent =>
	class provideDeviceType extends React.PureComponent {
		constructor() {
			super();

			this.state = {
				deviceType: {
					isMobile: false,
					isTablet: false,
					isDesktop: false,
				},
			};

			this.updateDeviceType = this.updateDeviceType.bind(this);
			this.handleUpdate = this.handleUpdate.bind(this);
		}		

		updateDeviceType(width) {
			this.setState({
				deviceType: {
					isMobile: isMobile(width),
					isTablet: isTablet(width),
					isDesktop: isDesktop(width),
				},
			});
		}

		handleUpdate(e, { width }) {
			this.updateDeviceType(width);
		}

		render() {
			return (
				<Responsive fireOnMount onUpdate={this.handleUpdate}>
					<DeviceTypeContext.Provider value={this.state.deviceType}>
						<WrappedComponent
							{...this.props}
						/>
					</DeviceTypeContext.Provider>
				</Responsive>
			);
		}
	}
