import React from 'react';
import { MenuItem } from 'semantic-ui-react';

import { externalLinks } from '../../../../../../../../Common/Config';
import { locations } from '../../../../../../../../Common/Constants';
import { InternalLink, ExternalLink, Icon, IconSizes } from '../../../../../../../../Common/Components';

const SubmenuCompany = ({ as: As, ...props }) => (
	<As {...props}>
		<MenuItem>
			<InternalLink to={locations.about}>About us</InternalLink>
		</MenuItem>
		<MenuItem>
			<InternalLink to={locations.careers}>Careers</InternalLink>
		</MenuItem>
		<MenuItem>
			<ExternalLink to={externalLinks.blog}>
				Blog <Icon className="icon" name="new-tab" size={IconSizes.base} isVerticallyAlignedMiddle />
			</ExternalLink>
		</MenuItem>
		<MenuItem>
			<InternalLink to={locations.contact}>Contact</InternalLink>
		</MenuItem>
	</As>
);

export default SubmenuCompany;
