import React from 'react';

import { InternalLink, Icon, IconSizes } from '.';

import './BackButton.scss';

const BackButton = ({ text, to }) => (
	<InternalLink to={to} className="single-career-template__back-button">
		<Icon name="arrow-left" size={IconSizes.base} /> {text}
	</InternalLink>
);

export default BackButton;
