import React from 'react';

import { Layout } from '../../Features';
import { provideDeviceType } from '../../Common/Components';
import { Hero, Message, Careers } from './Components/_index.js';

import './_CareersPage.scss';

const CareersPage = () => (
	<Layout className="careers-page">
		<Hero />
		<Message />
		<Careers />
	</Layout>
);

export default provideDeviceType(CareersPage);
