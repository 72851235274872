import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './_Hero.scss';

const Hero = () => (
	<Container as="section" className="about-us-page__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<h1 className="other-pages__h1 about-us-page__h1">
				About us
			</h1>
			<GreenDotHeading as='h2' className="other-pages__title about-us-page__title">
				Inspired by our vision of creating a transformative digital platform
			</GreenDotHeading>
		</Container>
	</Container>
);

export default Hero;
