import React from 'react';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';

import { Button, Responsive, validators } from '../../../Common/Components';
import createNewContact from '../../../pages/contact/_contactBuilder';
import requestTypes from '../../../pages/contact/_requestTypes';
import { api } from '../../../Common/Services';
import { resources } from '../../../Common/Constants';

import './SubscribePopupForm.scss';

const onSubmit = (values) => {
	const contact = createNewContact({ data: values, requestType: requestTypes.newsletter });

	api.post(resources.contactRequests, contact)
		.then(response => {
			console.log(response);
		})
		.catch(error => {
			console.log(error.message);
		});
};


const SubscribePopupForm = () => (
	<Form
		onSubmit={onSubmit}
		render={({ handleSubmit, pristine, invalid }) => (
			<div className="subscribe-popup__form-wrapper">
				<form className="subscribe-popup__form" onSubmit={handleSubmit}>
					<h3 className="subscribe-popup__title">
						Subscribe to our newsletter <Responsive tablet="br" computer="br" />
						and be the first to know what's new!
					</h3>
					<div className="subscribe-popup__row">
						<Field
							name="email"
							validate={validators.required}
							render={({ input, meta }) => (
								<div className="subscribe-popup__form-field">
								 	{meta.error && meta.touched && (
									 	<span className="subscribe-popup__error">
								 			{meta.error}
								 		</span>
									)}
									<input
										className={classNames('subscribe-popup__input', {
											'subscribe-popup__input--error': meta.error && meta.touched,
										})}
										type="email"
										placeholder="Work email"
										{...input}
									/>
								</div>
							)}
						/>
					</div>
					<div className="subscribe-popup__buttons">
						<Button
							type="submit"
							isHighlighted
						>
							Submit
						</Button>
					</div>
				</form>
			</div>
		)}
	/>
);

export default SubscribePopupForm;
