import React from 'react';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';

import { Container, Button, Checkbox, Grid, FormSuccessMessage, validators } from '../../../Common/Components';
import { api } from '../../../Common/Services';
import { resources, locations } from '../../../Common/Constants';
import createNewContact from '../../../pages/contact/_contactBuilder';
import requestTypes from '../../../pages/contact/_requestTypes';

import './_RequestAssessment.scss';

const contactFormInitialValues = {
	doSubscribeToEmail: true,
};

class RequestAssessment extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subscribeSuccessMessage: undefined,
			subscribeErrorMessage: undefined,
			isFormDisabled: false,
			isFormSuccess: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values) {
		this.setState({
			isFormDisabled: true,
		});
		const contact = createNewContact({ data: values, requestType: requestTypes.freeOpportunityAssessment });

		api.post(resources.contactRequests, contact)
			.then(response => {
				this.setState({
					subscribeSuccessMessage: `Thanks for requesting your free assessment. We will contact you shortly.`,
					isFormSuccess: true,
				});
			})
			.catch(error => {
				this.setState({
					subscribeErrorMessage: error.message,
					isFormDisabled: false,
				});
			});
	}

	render() {
		const { isFormSuccess, isFormDisabled, subscribeSuccessMessage, subscribeErrorMessage } = this.state;

		return (
			<Container className="request-assessment__form-wrapper" hasPadding isCentered >
			{ !isFormSuccess && <Form
					onSubmit={this.onSubmit}
					initialValues={contactFormInitialValues}
					render={({ handleSubmit, submitFailed, submitSucceeded }) => (
						<div>
							<h2 className="other-pages__h2 request-assessment__heading">
								Request your free assessment now!
							</h2>
							<form className="request-assessment__form" onSubmit={handleSubmit}>
								<Grid>
									<Field
										name="email"
										validate={validators.combineValidators(validators.required, validators.email)}
										render={({ input, meta }) => (
											<Grid.Column width={12} className="request-assessment__form-field">
												{
													meta.error && meta.touched && (
														<span className="request-assessment__error">{meta.error}</span>
													)
												}
												{(!submitFailed && submitSucceeded && !subscribeSuccessMessage && subscribeErrorMessage) && (
													<span className="request-assessment__error">{subscribeErrorMessage}</span>
												)}
												<input
													className={classNames('request-assessment__input', {
														'request-assessment__input--error': meta.error && meta.touched,
													})}
													type="email"
													placeholder="Work email"
													disabled={isFormDisabled}
													{...input}
												/>
											</Grid.Column>
										)}
									/>
									<Field
										name="doSubscribeToEmail"
										type="checkbox"
										render={({ input }) => (
											<Grid.Column width={12} className="request-assessment__form-field">
												<Checkbox
													className="request-assessment__checkbox"
													label="Yes, I want to be informed about relevant Leaf news occasionally."
													disabled={isFormDisabled}
													{...input}
												/>
											</Grid.Column>
										)}
									/>
									<Grid.Column width={12}>
										<div className="request-assessment__buttons">
											<Button
												type="submit"
												isHighlighted
												hasLargePadding
												disabled={isFormDisabled}
											>
												Submit
											</Button>
										</div>
									</Grid.Column>
								</Grid>
							</form>
						</div>
					)}
				/>}
				{ isFormSuccess && <FormSuccessMessage message={subscribeSuccessMessage} backToLocation={locations.index} /> }
				</Container>
		);
	}
}

export default RequestAssessment;
