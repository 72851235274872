import React from 'react';
import classNames from 'classnames';

const DataChartSvg = ({ className }) => (
	<svg className={classNames(className)} width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-514.000000, -285.000000)" id="Group-4">
				<g transform="translate(514.000000, 285.000000)">
					<path d="M42.3334157,-1.38555833e-13 C30.543112,0.00872855656 21,9.5566166 21,21.3339097 L21,42.6824769 L42.3487318,42.6824769 C54.1186139,42.6587616 63.6581028,33.1192727 63.666502,21.3490612 C63.666502,21.3456576 63.6665569,14.2293039 63.6666667,-1.38555833e-13 L42.3334157,-1.38555833e-13 Z" id="Fill-1" fill="#92BCEE" opacity="0.300000012"></path>
					<g id="analysis-(1)" fill="#FFFFFF">
						<path d="M62.8884238,4.35357015 C62.7266204,4.16244538 62.4950957,4.04307052 62.244853,4.02182054 L48.2468426,2.83219694 L47.9930825,0.8249493 C47.9277581,0.308574908 47.4530257,-0.057424662 46.93533,0.00745026172 L33.9499732,1.63194835 C33.8988443,1.61869837 33.8464591,1.60869838 33.7919384,1.60407338 L15.9438093,0.0870751681 C15.4298823,0.042075221 14.9633154,0.435324758 14.9195984,0.945699158 L14.6535271,4.04569551 L0.828751951,5.77519348 C0.317211778,5.8391934 -0.0570215661,6.31831784 0.00717220318,6.82769224 L7.06396436,62.6786266 C7.09599844,62.9333763 7.04110083,63.178126 7.14574547,63.4245007 C7.29247408,63.7696253 7.64170829,64 8.01845411,64 L54.4878337,64 C55.0110569,64 55.4349117,63.5782505 55.4349117,63.0576261 L55.4349117,61.8302526 L57.269145,61.9861274 C57.783072,62.0305023 58.2496389,61.6381278 58.2933559,61.1275034 L63.1076374,5.04119434 C63.1291191,4.79206963 63.0503529,4.54481993 62.8884238,4.35357015 Z M7.07137617,47.6277693 L2.0048421,7.52756642 L7.07137617,6.89381716 L7.07137617,47.6277693 Z M16.7263201,2.04519787 L25.0149793,2.74957204 L16.5753202,3.80544579 L16.7263201,2.04519787 Z M46.2319623,1.99507292 L46.7158652,5.82469342 L15.6175643,5.82469342 L46.2319623,1.99507292 Z M56.4867599,60.0280047 L55.4349117,59.9386298 L55.4349117,32.3992872 C55.4349117,31.8787878 55.0110569,31.4569133 54.4878337,31.4569133 C53.9646105,31.4569133 53.5407558,31.8786628 53.5407558,32.3992872 L53.5407558,62.1151272 L8.96553204,62.1151272 L8.96553204,7.7094412 L53.5408814,7.7094412 L53.5408814,28.0016673 C53.5408814,28.5222917 53.9647362,28.9440412 54.4879594,28.9440412 C55.0111825,28.9440412 55.4350373,28.5222917 55.4350373,28.0016673 L55.4350373,6.76706731 C55.4350373,6.24656792 55.0111825,5.82469342 54.4879594,5.82469342 L48.6249703,5.82469342 L48.4884172,4.74431969 L61.139866,5.81956843 L56.4867599,60.0280047 Z" id="Shape" fillRule="nonzero"></path>
						<path d="M48.8452493,10.6666667 L43.8724235,10.6666667 C43.5606325,10.6666667 43.2694956,10.824462 43.0967181,11.0874126 L40.9862849,14.295459 C38.7146999,12.6464409 35.9335635,11.6744765 32.9324048,11.6744765 C32.4174116,11.6744765 32,12.0973531 32,12.6193688 L32,25.7217744 C32,26.2436648 32.417288,26.6666667 32.9324048,26.6666667 L45.8616528,26.6666667 C46.3767697,26.6666667 46.7940576,26.2437901 46.7940576,25.7217744 C46.7940576,21.6989323 45.1162484,18.0662546 42.4331882,15.5029258 L44.371586,12.5564512 L48.845373,12.5564512 C49.3603661,12.5564512 49.7777778,12.1335746 49.7777778,11.6115589 C49.7777778,11.0895432 49.3602425,10.6666667 48.8452493,10.6666667 Z M44.8933815,24.7768822 L33.8648096,24.7768822 L33.8648096,13.6006079 C36.1260057,13.7775793 38.2120745,14.5947563 39.9504857,15.8700287 L39.118507,17.1347731 C38.8586606,17.5295749 38.9348459,18.0764067 39.2937581,18.3818454 C39.7140143,18.7395483 40.3659185,18.6451719 40.6700415,18.1830658 L41.382053,17.1006823 C43.36448,19.0962486 44.6653201,21.7864154 44.8933815,24.7768822 Z" id="Shape" fillRule="nonzero"></path>
						<path d="M49.710866,41.7019067 L41.0650477,41.7019067 L39.5416493,39.3385935 C42.009438,36.8591123 43.5300473,33.4874112 43.5300473,29.7777778 C43.5300473,29.2657778 43.1022888,28.8510148 42.5742466,28.8510148 L30.2763798,28.8510148 L30.2763798,16.926763 C30.2763798,16.4148859 29.8484945,16 29.3205792,16 C21.4855242,16 15.1111111,22.1807554 15.1111111,29.7777778 C15.1111111,37.3748001 21.4855242,43.5555556 29.3205792,43.5555556 C32.6324747,43.5555556 35.6830749,42.4511599 38.1019262,40.6024282 L39.7247208,43.1200175 C39.8994248,43.3909534 40.2057272,43.5555556 40.5352306,43.5555556 L49.710866,43.5555556 C50.2387814,43.5555556 50.6666667,43.1407926 50.6666667,42.6287926 C50.6666667,42.1167926 50.2387814,41.7019067 49.710866,41.7019067 Z M36.2483906,34.2292887 C35.9687121,33.7949799 35.3788009,33.6631999 34.9312646,33.9345045 C34.4836015,34.2056863 34.3474389,34.7775527 34.6272442,35.2116156 L37.0822281,39.020207 C34.9638473,40.6958544 32.2612604,41.7021525 29.320706,41.7021525 C22.5397068,41.7021525 17.0228392,36.35289 17.0228392,29.7779007 C17.0228392,23.5146598 22.02878,18.3638049 28.3649053,17.8892983 L28.3649053,29.7779007 C28.3649053,30.2897777 28.7927906,30.7046637 29.320706,30.7046637 L41.5818063,30.7046637 C41.3683074,33.3846424 40.2369153,35.8190086 38.4931719,37.7121177 L36.2483906,34.2292887 Z" id="Shape" fillRule="nonzero"></path>
						<path d="M21.2939428,47.1111111 L13.3727238,47.1111111 C12.8601324,47.1111111 12.4444444,47.508924 12.4444444,48 C12.4444444,48.491076 12.8600092,48.8888889 13.3727238,48.8888889 L21.2939428,48.8888889 C21.8066574,48.8888889 22.2222222,48.491076 22.2222222,48 C22.2222222,47.508924 21.8066574,47.1111111 21.2939428,47.1111111 Z" id="Path"></path>
						<path d="M32.8494984,47.1111111 L24.9282794,47.1111111 C24.4155648,47.1111111 24,47.508924 24,48 C24,48.491076 24.4155648,48.8888889 24.9282794,48.8888889 L32.8494984,48.8888889 C33.362213,48.8888889 33.7777778,48.491076 33.7777778,48 C33.7777778,47.508924 33.362213,47.1111111 32.8494984,47.1111111 Z" id="Path"></path>
						<path d="M26.6031682,52.4444444 L13.3968318,52.4444444 C12.870928,52.4444444 12.4444444,52.8422573 12.4444444,53.3333333 C12.4444444,53.8244094 12.8708017,54.2222222 13.3968318,54.2222222 L26.6031682,54.2222222 C27.1291983,54.2222222 27.5555556,53.8244094 27.5555556,53.3333333 C27.5555556,52.8422573 27.129072,52.4444444 26.6031682,52.4444444 Z" id="Path"></path>
						<path d="M33.6410309,52.4444444 L30.3589691,52.4444444 C29.7926179,52.4444444 29.3333333,52.8422573 29.3333333,53.3333333 C29.3333333,53.8244094 29.7924819,54.2222222 30.3589691,54.2222222 L33.6410309,54.2222222 C34.2075181,54.2222222 34.6666667,53.8244094 34.6666667,53.3333333 C34.6665306,52.8422573 34.2075181,52.4444444 33.6410309,52.4444444 Z" id="Path"></path>
						<path d="M17.6737505,56.8888889 L13.4373607,56.8888889 C12.889077,56.8888889 12.4444444,57.2867017 12.4444444,57.7777778 C12.4444444,58.2688538 12.8889453,58.6666667 13.4373607,58.6666667 L17.6737505,58.6666667 C18.2221658,58.6666667 18.6666667,58.2688538 18.6666667,57.7777778 C18.6666667,57.2867017 18.2221658,56.8888889 17.6737505,56.8888889 Z" id="Path"></path>
						<path d="M33.7056954,56.8888889 L21.4054157,56.8888889 C20.874772,56.8888889 20.4444444,57.2867017 20.4444444,57.7777778 C20.4444444,58.2688538 20.8746445,58.6666667 21.4054157,58.6666667 L33.7056954,58.6666667 C34.2364666,58.6666667 34.6666667,58.2688538 34.6666667,57.7777778 C34.6666667,57.2867017 34.2364666,56.8888889 33.7056954,56.8888889 Z" id="Path"></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default DataChartSvg;
