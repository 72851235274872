import React from 'react';

import { Layout } from '../../Features';
import { provideDeviceType } from '../../Common/Components';
import { Hero, Screenshots, RequestAssessment, Benefits } from './Components/_index.js';

import './_FreeOpportunityAssessment.scss';

const FreeOpportunityAssessment = () => (
	<Layout className="free-opportunity-assessment">
		<Hero />
		<Benefits />
		<RequestAssessment />
		<Screenshots />
	</Layout>
);

export default provideDeviceType(FreeOpportunityAssessment);
