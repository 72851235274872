import React from 'react';

import settings from '../../Common/Config';
import { locations, platformLocations } from '../../Common/Constants';
import { Container, Card, Grid, InternalLink, ExternalLink, Icons } from '../../Common/Components';
import SubscribePopup from './SubscribePopup';

import './ContactSection.scss';

class ContactSection extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			isSubscribePopupOpened: false,
		};

		this.handleOpenSubscribePopup = this.handleOpenSubscribePopup.bind(this);
		this.handleCloseSubscribePopup = this.handleCloseSubscribePopup.bind(this);
	}

	handleOpenSubscribePopup() {
		this.setState({
			isSubscribePopupOpened: true,
		});
	}

	handleCloseSubscribePopup() {
		this.setState({
			isSubscribePopupOpened: false,
		});
	}

	render() {
		return (
			<Container as="section" className="contact-section" hasPadding hasMaxWidth>
				<Grid textAlign="center">
					<Grid.Column mobile={12} tablet={8} computer={4}>
						<InternalLink to={locations.contact}>
							<Card
								as="button"
								className="contact-section__card"
								hasPadding={false}
								isClickable
							>
								<Icons.PhoneSvg className="contact-section__icon" />
								Get in touch
							</Card>
						</InternalLink>
					</Grid.Column>
				</Grid>
			</Container>
		);
	}
}

export default ContactSection;
