import React from 'react';

import { Icons, Button } from '../../../Common/Components';

import './SubscribePopupMessage.scss';

const SubscribePopupError = ({ onConfirmClick }) => (
	<div className="subscribe-popup__message">
		<Icons.PopupGraphicErrorSvg className="subscribe-popup__message-icon" />
		<h3 className="subscribe-popup__title">
			Sorry, but something went wrong...<br />
			Please try again later.
		</h3>
		<div className="subscribe-popup__buttons">
			<Button onClick={onConfirmClick}>
				Got it
			</Button>
		</div>
	</div>
);

export default SubscribePopupError;
