import React from 'react';

import { Layout } from '../../Features';
import { provideDeviceType } from '../../Common/Components';
import { Hero, ContactForm } from './Components/_index.js';

import './_ContactPage.scss';

const ContactPage = () => (
	<Layout className="contact-page">
		<Hero />
		<ContactForm />
	</Layout>
);

export default provideDeviceType(ContactPage);
