import React from 'react';

import { externalLinks } from '../../../Common/Config';
import { ExternalLink, Button } from '../../../Common/Components';

const ApplyButton = (props) => (
	<ExternalLink to={props.link} className="single-career-template__apply-button">
		<Button isHighlighted>
			Apply for this job
		</Button>
	</ExternalLink>
);

export default ApplyButton;
