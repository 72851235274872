import React from 'react';
import classNames from 'classnames';

import { Responsive, withDeviceType } from '../../../../../../Common/Components';
import NavMenuDesktop from './NavMenuDesktop';
import NavMenuMobile from './NavMenuMobile';

import './NavMenu.scss';

const NavMenu = ({ deviceType }) => (
	<nav className={classNames('nav-menu', { 'nav-menu--desktop': deviceType.isDesktop })}>
		<Responsive
			mobile={NavMenuMobile}
			tablet={NavMenuMobile}
			computer={NavMenuDesktop}
		/>
	</nav>
);

export default withDeviceType(NavMenu);
