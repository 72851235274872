import React from 'react';

import NavMenuContent from '../NavMenuContent';
import NavMenuDesktopSubmenu from './NavMenuDesktopSubmenu';

import './NavMenuDesktop.scss';

const NavMenu = () => (
	<div className="nav-menu-desktop">
		<NavMenuContent submenuComponent={NavMenuDesktopSubmenu} />
	</div>
);

export default NavMenu;
