import React from 'react';

import { locations } from '../../../Common/Constants';
import { Container, Button, InternalLink, withDeviceType } from '../../../Common/Components';

import './_SeeMoreBenefitsLink.scss';

const SeeMoreBenefitsLink = ({ isMobile }) => (
	<Container isCentered className="landing-page__see-more-benefits-link">
		<InternalLink to={locations.whyLeaf}>
			<Button isSmall={isMobile}>
				Learn More
			</Button>
		</InternalLink>
	</Container>
);

export default withDeviceType(SeeMoreBenefitsLink);
