import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './_Hero.scss';

const Hero = () => (
	<Container as="section" className="why-leaf-page__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<h1 className="other-pages__h1 why-leaf-page__h1">
				Why Leaf
			</h1>
			<GreenDotHeading as='h2' className="other-pages__title why-leaf-page__title">
				Smart Contracts that adapt to your business needs
			</GreenDotHeading>
		</Container>
	</Container>
);

export default Hero;
