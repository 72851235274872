import React from 'react';
import classNames from 'classnames';

const SignUpSvg = ({ className }) => (
    <svg className={classNames(className)} width="42px" height="34px" viewBox="0 0 42 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="icons" transform="translate(-347.000000, -240.000000)" stroke="#FFFFFF" strokeWidth="2">
                <path d="M381.777778,255.222222 L381.777778,249.888889 L381.777778,255.222222 L387.111111,255.222222 L381.777778,255.222222 Z M381.777778,255.222222 L381.777778,260.555556 L381.777778,255.222222 L376.444444,255.222222 L381.777778,255.222222 Z M374.666667,273 L348,273 L348,269.444444 C348,265.517086 351.183753,262.333333 355.111111,262.333333 L367.555556,262.333333 C371.482914,262.333333 374.666667,265.517086 374.666667,269.444444 L374.666667,273 Z M361.333333,255.222222 C357.405975,255.222222 354.222222,252.038469 354.222222,248.111111 C354.222222,244.183753 357.405975,241 361.333333,241 C365.260692,241 368.444444,244.183753 368.444444,248.111111 C368.444444,252.038469 365.260692,255.222222 361.333333,255.222222 Z" id="Sign-up"></path>
            </g>
        </g>
    </svg>
);

export default SignUpSvg;
