import React from 'react';
import classNames from 'classnames';

const PhoneSvg = ({ className }) => (
    <svg className={classNames(className)} width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="icons" transform="translate(-262.000000, -240.000000)" stroke="#FFFFFF" strokeWidth="2">
                <path d="M294.935609,264.958139 L294.935609,269.775427 C294.93928,270.679705 294.561543,271.543626 293.8952,272.154951 C293.228856,272.766275 292.335661,273.068346 291.435046,272.986953 C286.493847,272.450051 281.747478,270.761598 277.577313,268.057261 C273.697515,265.591875 270.408122,262.302483 267.942737,258.422684 C265.228962,254.233577 263.540125,249.464125 263.013045,244.500721 C262.931911,243.602894 263.231865,242.712306 263.839637,242.046507 C264.447408,241.380707 265.307028,241.001007 266.208513,241.000158 L271.025801,241.000158 C272.637769,240.984293 274.011718,242.165889 274.237327,243.76207 C274.440653,245.303709 274.817729,246.817398 275.361361,248.274263 C275.80252,249.447875 275.520355,250.770913 274.638767,251.662423 L272.599449,253.701742 C274.885341,257.721842 278.213925,261.050426 282.234026,263.336318 L284.273344,261.297 C285.164854,260.415412 286.487892,260.133247 287.661504,260.574406 C289.118369,261.118038 290.632058,261.495114 292.173697,261.69844 C293.788392,261.926234 294.976058,263.327956 294.935609,264.958139 Z" id="Phone"></path>
            </g>
        </g>
    </svg>
);

export default PhoneSvg;
