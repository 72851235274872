import React from 'react';

import { locations } from '../../../Common/Constants';
import {
	Container,
	Button,
	InternalLink,
	GreenDotHeading,
	Responsive,
	withDeviceType,
	ExternalLink,
} from '../../../Common/Components';
import ScrollIndicator from './_ScrollIndicator';

import './_Hero.scss';

const Hero = ({ deviceType }) => (
	<Container as="section" className="landing-page__hero" isCentered isFullScreen>
		<Container as="div" className="landing-page__message">
			<div>
				<img width="100" height="90"
					src="/images/logos/gartner.png"
					alt="Gartner logo"
				/>
			</div>
			<div className="landing-page__message-paragraph-wrapper">
				<p className="landing-page__message-paragraph">We are proud to announce that Leaf Logistics has been named</p>
				<p className="landing-page__message-paragraph">a Gartner Cool Vendor in the September 2020 Cool Vendors in</p>
				<p className="landing-page__message-paragraph">Supply Chain Execution Technologies report.</p>
				<br/>
				<p className="landing-page__message-paragraph"><ExternalLink className="landing_page__message_link" to="https://medium.com/forward-thoughts/leaf-logistics-named-a-gartner-cool-vendor-in-supply-chain-execution-technologies-bfe13e9b314a/">Learn why</ExternalLink></p>
			</div>
		</Container>
	<Container as="section" isCentered isFullScreen>
		{/**/}
		<Container hasPadding hasMaxWidth isCentered>
			<GreenDotHeading className="landing-page__title" as='h1'>
				Adaptable Contracting
				<br />
				with Network Scale
			</GreenDotHeading>
			<h2 className="landing-page__subtitle">
				Confidently buy and sell transportation capacity to meet your changing business needs
			</h2>
			<div className="landing-page__hero-buttons">
				<InternalLink to={locations.contact}>
					<Button isHighlighted isSmall={deviceType.isMobile}>
						Learn more
					</Button>
				</InternalLink>
			</div>
			{/* <Responsive tablet={ScrollIndicator} computer={ScrollIndicator} /> */}
		</Container>
	</Container>
	</Container>
);

export default withDeviceType(Hero);
