import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

const Checkbox = ({ className, label, ...props }) => (
	<label className={classNames('le-checkbox', className)}>
		<input
			className="le-checkbox__input"
			type="checkbox"
			{...props}
		/>
		<span className="le-checkbox__checkmark" />
		<span className="le-checkbox__label">
			{label}
		</span>
	</label>
);

export default Checkbox;
