import React from 'react';

import { locations } from '../../../Common/Constants';
import { Container, Grid, InternalLink, Button } from '../../../Common/Components';

import './_Collaborate.scss';

const Collaborate = () => (
	<Container className="about-us-page__collaborate" as="section" isCentered>
		<Container hasPadding hasMaxWidth>
			<h2 className="other-pages__h2 about-us-page__collaborate-title">
				Want to join the team?
			</h2>
			<Grid textAlign="center">
				<Grid.Column mobile={12} tablet={10} computer={8}>
					<p className="other-pages__h3 about-us-page__collaborate-message">
					Leaf is always looking for forward-thinking talent. <br/>
					Check the open positions listing for career opportunities.
					</p>
					<InternalLink to={locations.careers}>
						<Button className="about-us-page__collaborate-button" isHighlighted>
							See open positions
						</Button>
					</InternalLink>
				</Grid.Column>
			</Grid>
		</Container>
	</Container>
);

export default Collaborate;
