import React from 'react';

import { locations } from '../../../../../../Common/Constants';
import { InternalLink } from '../../../../../../Common/Components';
import { FooterMenuItem } from '../';

const FooterMenuLegal = ({ as: As, ...props }) => (
	<As name="Legal" {...props}>
		<FooterMenuItem>
			<InternalLink to={locations.legalTermsAndConditions}>Terms & conditions</InternalLink>
		</FooterMenuItem>
		<FooterMenuItem>
			<InternalLink to={locations.legalPrivacyPolicy}>Privacy policy</InternalLink>
		</FooterMenuItem>
	</As>
);

export default FooterMenuLegal;