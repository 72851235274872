import React from 'react';
import ReactHelmet from 'react-helmet';

const Helmet = ({ siteMetadata, children }) => (
	<ReactHelmet
		title={siteMetadata.title}
		meta={[
			{ name: 'description', content: 'Dynamic Contracting to guarantee service and cost reliability' },
			{ name: 'keywords', content: 'leaf,logistics,exchange' },
			{ name: 'author', content: 'LEAF' },
		]}
	>
		{children}
	</ReactHelmet>
);

export default Helmet;
