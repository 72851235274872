import React from 'react';

import { Icons, Button } from '../../../Common/Components';

import './SubscribePopupMessage.scss';

const SubscribePopupSuccess = ({ onConfirmClick }) => (
	<div className="subscribe-popup__message">
		<Icons.PopupGraphicSuccessSvg className="subscribe-popup__message-icon" />
		<h3 className="subscribe-popup__title">
			You are now subscribed to our newsletter!
		</h3>
		<div className="subscribe-popup__buttons">
			<Button onClick={onConfirmClick}>
				Got it
			</Button>
		</div>
	</div>
);

export default SubscribePopupSuccess;
