import React from 'react';

import './FooterCopyright.scss';

const getCurrentYear = () => new Date().getFullYear();

const FooterCopyright = () => (
	<span className="footer__copyright">
		&copy; {getCurrentYear()}, Leaf Logistics Inc. All rights reserved.
	</span>
);

export default FooterCopyright;
