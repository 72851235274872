import React from 'react';

import { GreenDotHeading, DeorphanedText } from '../../../Common/Components';

const Benefit = ({ icon: Icon, title, text }) => (
	<article className="why-leaf-page__benefit">
		<Icon className="why-leaf-page__benefit-icon" />
		<GreenDotHeading className="other-pages__h3 why-leaf-page__benefit-title" as='h3'>
			{title}
		</GreenDotHeading>
		<p className="other-pages__body-text why-leaf-page__benefit-text">
			<DeorphanedText>
				{text}
			</DeorphanedText>
		</p>
	</article>
);

export default Benefit;
