import React from 'react';
import classNames from 'classnames';

const QuoteSvg = ({ className }) => (
    <svg className={classNames(className)} width="81px" height="72px" viewBox="0 0 81 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-514.000000, -476.000000)" fill="#183B5A" id="“">
                <path d="M572.521498,512.181341 C572.521498,514.771517 574.059392,516.147528 577.135227,516.309414 L581.991782,516.552242 C585.229503,516.714128 588.102936,518.252022 590.612169,521.16597 C593.121402,524.079918 594.376,527.398531 594.376,531.121909 C594.376,535.654718 592.757164,539.458982 589.519444,542.534816 C586.281724,545.61065 582.234635,547.148544 577.378054,547.148544 C571.712044,547.148544 567.057891,544.922645 563.415456,540.470779 C559.773021,536.018914 557.951831,530.312518 557.951831,523.35142 C557.951831,510.400539 563.051164,497.126086 573.249982,483.527662 C576.97336,478.509195 580.211032,476 582.963094,476 C586.038928,476 587.576822,477.618836 587.576822,480.856556 C587.576822,482.151644 586.686462,483.932363 584.905716,486.198767 C580.534794,491.702892 576.892413,498.259176 573.978465,505.867819 C573.007149,508.457995 572.521498,510.562481 572.521498,512.181341 Z M528.32684,512.181341 C528.32684,514.771517 529.864734,516.147528 532.940568,516.309414 L537.797124,516.552242 C541.19673,516.714128 544.151105,518.252022 546.660338,521.16597 C549.169571,524.079918 550.424169,527.398531 550.424169,531.121909 C550.424169,535.654718 548.805333,539.458982 545.567613,542.534816 C542.329893,545.61065 538.282804,547.148544 533.426224,547.148544 C527.760213,547.148544 523.106061,544.922645 519.463625,540.470779 C515.82119,536.018914 514,530.312518 514,523.35142 C514,510.400539 519.099333,497.126086 529.298151,483.527662 C533.021529,478.509195 536.259201,476 539.011263,476 C542.087097,476 543.624991,477.618836 543.624991,480.856556 C543.624991,482.151644 542.734631,483.932363 540.953885,486.198767 C537.716165,490.245918 534.802261,494.940541 532.212085,500.282779 C529.621908,505.625018 528.32684,509.591165 528.32684,512.181341 Z"></path>
            </g>
        </g>
    </svg>
);

export default QuoteSvg;
