import requestTypes from './_requestTypes';

export default ({ data, requestType }) => ({
	requestType,
	email: data.email,
	firstName: data.firstName,
	lastName: data.lastName,
	message: data.message,
	phone: data.phone,
	doSubscribeToEmail: (requestType === requestTypes.newsletter) ? true : data.doSubscribeToEmail,
});
