import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './_DetailsContentTitle.scss'

const DetailsContentTitle = ({ title, children }) => (
	<Container isCentered>
		<GreenDotHeading as='h2' className="other-pages__h2 details-content-title__title">
			{title}
		</GreenDotHeading>
		<p className="other-pages__body-text details-content-title__text">
			{children}
		</p>
	</Container>
);

export default DetailsContentTitle;
