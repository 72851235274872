import React from 'react';

import { provideDeviceType } from '../../Common/Components';
import { Layout } from '../../Features';
import { Hero, DetailsContent } from './Components/_index.js';

import './_FreeOpportunityAssessmentDetails.scss';

const FreeOpportunityAssessmentDetails = () => (
	<Layout className="free-opportunity-assessment-details">
		<Hero />
		<DetailsContent />
	</Layout>
);

export default provideDeviceType(FreeOpportunityAssessmentDetails);
