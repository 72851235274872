import React from 'react';

import { provideDeviceType } from '../../Common/Components';
import { Layout } from '../../Features';
import { Hero, Content } from './Components';

import './LegalPage.scss';

const SingleCareerTemplate = ({ pageContext: { title, markdownSrc } }) => (
	<Layout className="privacy-policy-page">
		<Hero title={title} />
		<Content markdownSrc={markdownSrc} />
	</Layout>
);

export default provideDeviceType(SingleCareerTemplate);
