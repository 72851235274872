import React from 'react';
import classNames from 'classnames';

import './GreenDotHeading.scss';

const GreenDotHeading = ({ as: As = 'h1', isCentered, className, children, hasThreeDots }) => (
	<As
		className={classNames('green-dot-heading', {
			'green-dot-heading--centered': isCentered,
		}, className)}
	>
		{children}
		<span className="green-dot-heading__green-dot">.</span>
		{ hasThreeDots && <span className="green-dot-heading__green-dot">.</span> }
		{ hasThreeDots && <span className="green-dot-heading__green-dot">.</span> }
	</As>
);

export default GreenDotHeading;
