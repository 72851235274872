import React from 'react';

import { ExternalLink, Icons } from '../../../../../Common/Components';
import { externalLinks } from '../../../../../Common/Config';

import './FooterSocial.scss';

const FooterSocial = () => (
	<div className="footer__social">
		<ExternalLink className="footer__social-link" to={externalLinks.linkedin}>
			<Icons.LinkedinSvg className="footer__social-icon" />
		</ExternalLink>
		<ExternalLink className="footer__social-link" to={externalLinks.twitter}>
			<Icons.TwitterSvg className="footer__social-icon" />
		</ExternalLink>
	</div>
);

export default FooterSocial;
