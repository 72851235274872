import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Container } from '../../../Common/Components';
import CareersDepartment from './_CareersDepartment';

import './_Careers.scss';

const groupJobPostingsByDepartment = allJobPostingsList =>
	allJobPostingsList.reduce((jobPostingsByDepartment, jobPosting) => ({
		...jobPostingsByDepartment,
		[jobPosting.department]: [
			...jobPostingsByDepartment[jobPosting.department] || [],
			jobPosting,
		],
	}), {});

const Careers = () => (
	<StaticQuery
		query={graphql`
			query ActiveJobPostingsQuery {
				postgres {
					allJobPostingsList(
						condition: { active: true },
						orderBy:DEPARTMENT_ASC,
				  	) {
						id
						department
						office
						title
						active
					}
				}
			}
		`}
		render={data => (
			<Container as="section" hasPadding hasMaxWidth className="careers-page__careers">
				{Object.entries(groupJobPostingsByDepartment(data.postgres.allJobPostingsList)).map(([department, careers]) => (
					<CareersDepartment
						key={department}
						name={department}
						careers={careers}
					/>
				))}
			</Container>
		)}
	/>
);

export default Careers;
