import settings from './settings.json';
import platformSettings from './platformSettings';
import externalLinks from './externalLinks';

export default settings;

export {
	platformSettings,
	externalLinks,
};
