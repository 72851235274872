import React from 'react';

import { Container, Grid, Icons, DeorphanedText } from '../../../Common/Components';

import './_TestimonialsSliderCard.scss';

const TestimonialsSliderCard = ({ imageSrc, text, name, position }) => (
	<Container hasPadding>
		<Grid textAlign="center">
			<Grid.Column mobile={12} tablet={12} computer={8}>
				<div className="testimonials-slider-card">
					<Container isCentered className="testimonials-slider-card__image-wrapper">
						<img className="testimonials-slider-card__image" src={imageSrc} alt={name} />
					</Container>
					<div className="testimonials-slider-card__quotemark">
						<Icons.QuoteSvg />
					</div>
					<blockquote className="testimonials-slider-card__text">
						<DeorphanedText>
							{text}
						</DeorphanedText>
					</blockquote>
					<div className="testimonials-slider-card__referee">
						{name}<br />
						{position}
					</div>
				</div>
			</Grid.Column>
		</Grid>
	</Container>
);

export default TestimonialsSliderCard;
