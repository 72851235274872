import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const combineValidators = (...validators) => value => validators.reduce((errorMessage, validator) => errorMessage || validator(value), undefined);

export const required = value => (value ? undefined : 'Required');

export const email = value =>
	(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined);


export const phone = (value) => {
	const errorMessage = 'Invalid phone number';
	try {
		return parsePhoneNumberFromString(value).isValid() ? undefined : errorMessage;
	} catch (error) {
		return errorMessage;
	}
}
