import React from "react";
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import './Markdown.scss';

const Markdown = ({ source, allowedTypes, renderers, className }) => (
	<ReactMarkdown
		className={classNames('markdown', className)}
		source={source}
		escapeHtml={false}
		allowedTypes={allowedTypes}
		renderers={renderers}
	/>
);

export default Markdown;
