import React from 'react';
import classNames from 'classnames';

import { Container } from '../../../../../../../Common/Components';
import NavMenuContent from '../NavMenuContent';
import NavMenuMobileSubmenu from './NavMenuMobileSubmenu';
import NavMenuMobileControlButton from './NavMenuMobileControlButton';

import './NavMenuMobile.scss';

class NavMenuMobile extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			isMenuOpened: false,
			openedSubmenu: null,
		};

		this.navMenuRef = null;
		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.handleClickOnControlButton = this.handleClickOnControlButton.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleToggleSubmenuClick = this.handleToggleSubmenuClick.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	toggleMenu() {
		this.setState({
			isMenuOpened: !this.state.isMenuOpened,
		});
	}

	closeMenu() {
		this.setState({
			isMenuOpened: false,
		});
	}

	handleClickOnControlButton() {
		this.toggleMenu();
	}

	handleClickOutside() {
		this.closeMenu();
	}

	handleClick(e) {
		if (this.navMenuRef.contains(e.target)) {
			return;
		}

		this.handleClickOutside();
	}

	handleToggleSubmenuClick(submenuName) {
		const newOpenedSubmenu = this.state.openedSubmenu !== submenuName ? submenuName : null;

		this.setState({
			openedSubmenu: newOpenedSubmenu,
		});
	}

	render() {
		const { isMenuOpened, openedSubmenu } = this.state;

		return (
			<div
				className="nav-menu-mobile"
				ref={node => this.navMenuRef = node}
			>
				<NavMenuMobileControlButton
					isMenuOpened={isMenuOpened}
					onClick={this.handleClickOnControlButton}
				/>
				<div className={classNames('nav-menu-mobile__content', {
					'nav-menu-mobile__content--visible': isMenuOpened,
				})}
				>
					<Container
						className="nav-menu-mobile__content-wrapper"
						hasPadding
					>
						<NavMenuContent
							submenuComponent={NavMenuMobileSubmenu}
							openedSubmenu={openedSubmenu}
							handleToggleSubmenuClick={this.handleToggleSubmenuClick}
						/>
					</Container>
				</div>
			</div>
		);
	}
}

export default NavMenuMobile;
