import React from 'react';
import { Modal } from 'semantic-ui-react';

import SubscribePopupCloseButton from './SubscribePopupCloseButton';
import SubscribePopupForm from './SubscribePopupForm';
import SubscribePopupMessageSuccess from './SubscribePopupMessageSuccess';
import SubscribePopupMessageError from './SubscribePopupMessageError';

import './SubscribePopup.scss';

class SubscribePopup extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isSubscriptionSubmitted: false,
			isSubscriptionSuccessful: false,
		};
	}

	render() {
		const { isOpened, handleClose } = this.props;
		const { isSubscriptionSubmitted, isSubscriptionSuccessful } = this.state;

		return (
			<Modal
				className="subscribe-popup"
				open={isOpened}
				onClose={handleClose}
			>
				<Modal.Content>
					<SubscribePopupCloseButton onClick={handleClose} />
					{!isSubscriptionSubmitted && (
						<SubscribePopupForm />
					)}
					{isSubscriptionSubmitted && isSubscriptionSuccessful && (
						<SubscribePopupMessageSuccess onConfirmClick={handleClose} />
					)}
					{isSubscriptionSubmitted && !isSubscriptionSuccessful && (
						<SubscribePopupMessageError onConfirmClick={handleClose} />
					)}
				</Modal.Content>
			</Modal>
		);
	}
}

export default SubscribePopup;
