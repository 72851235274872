import React from 'react';

import { locations } from '../../Common/Constants';
import { Container, provideDeviceType, InternalLink, Icons } from '../../Common/Components';
import { Layout } from '../../Features';

import './_NotFoundPage.scss';

const NotFoundPage = () => (
  <Layout className="not-found-page" hasNavigation={false}>
    <Container className="not-found-page__background" isHalfScreen isCentered />
    <Container className="not-found-page__hero" hasPadding>
      <Icons.Graphic404Svg className="not-found-page__image" />
      <div className="not-found-page__message">
        <h3 className="other-pages__h3">
          The page you are looking for doesn't exist.
        </h3>
        <h3 className="other-pages__h3">
          Return to <InternalLink className="not-found-page__link" to={locations.index}>homepage</InternalLink>.
        </h3>
      </div>
    </Container>
  </Layout >
);

export default provideDeviceType(NotFoundPage);
