import React from 'react';

import { Container, Grid, GreenDotHeading } from '../../../Common/Components';
import Employee from './_Employee';

import './_Employees.scss';

const Employees = () => (
	<Container as="section" hasPadding hasMaxWidth className="about-us-page__employees">
		<section className="about-us-page__employees-section">
			<GreenDotHeading as='h2' className="other-pages__h2 about-us-page__employees-heading">
				Team
			</GreenDotHeading>
			<Grid textAlign="left">
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Anshu_P.png"
						name="Anshu Prasad"
						position="CEO"
						bio="Anshu has 20 years of experience in logistics,
							technology and analytics. He built and led A.T.
							Kearney’s global Analytics Practice, working
							with clients to utilize technology and data
							to empower business transformation.
							He also helped start and grow technology
							and analytics businesses, building teams
							in the US, Europe and Asia."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Stefan_F.png"
						name="Stefan Friederichs"
						position="Engineering"
						bio="Stefan combines extensive technology and startup
							experience with a deep understanding of the logistics industry.
							He developed a wide range of software systems,
							including award-winning optimization models to solve
							some of the most complex problems in the logistics industry.
							He holds a Master in Information Systems and a PhD in Operations Research."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Jeff_R.png"
						name="Jeff Ryan"
						position="Customer Success"
						bio="Jeff brings his experience as an industry executive
							responsible for operations and procurement at companies
							like Bristol Myers Squibb and leadership roles in technology
							and consulting firms to help carriers, shippers and 3PLs best
							use the Leaf platform."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Bryan_S.png"
						name="Bryan Schulte"
						position="Analytics"
						bio="Bryan has extensive experience in logistics and technology startups,
							and has done groundbreaking work with both shippers and carriers to
							identify opportunities to improve yield,
							asset utilization and network balance."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Andrea_P.png"
						name="Andrea Pope"
						position="Analytics"
						bio="Andrea is a passionate and creative problem solver, interested in using
							data science techniques to drive business decisions. She has over 15 years
							of experience supporting and implementing best-in-class transportation solutions
							across modes worldwide. She holds a Master in Information and Data Science
							from UC Berkeley. "
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Petr_K.png"
						name="Petr Kadlec"
						position="Analytics"
						bio="Petr has more than 10 years of experience in supply chain
							and transportation analytics gained in the industry and at
							a top management consulting firm. He helped numerous clients
							solve their supply chain problems by applying advanced data
							science techniques. He holds a Master in Computer Science and
							a PhD in Machine Learning."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Shawn_G.png"
						name="Shawn Gillespie"
						position="Customer Success"
						bio="Shawn has spent close to 20 years in transportation holding key roles in sales and
							operations managing both asset and non-asset solutions. He successfully developed
							a national freight agency, a freight brokerage and a national owner operator network.
							Shawn combines his industry experience and operational knowledge to help carriers
							and shippers better engage with the Leaf platform."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Leigh_W.png"
						name="Leigh Walzer"
						position="Data Science"
						bio="Leigh has experience applying cutting edge data science techniques to solve business problems across an
							array of industries. Prior to becoming a data scientist, he worked in capital markets with focus on business
							and financial restructuring. He also has management consulting experience including several years
							working with technology startups."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Silvia_K.png"
						name="Silvia Knox"
						position="Customer Success"
						bio="Silvia has a background in commodity sales and logistics. Prior to Leaf, she worked at an American
							multinational investment bank where she provided corporate clients with physical base metal financing
							solutions including hedging derivatives.  Previously, she worked for a leading trader of physical
							agricultural fertilizers where she helped build the container shipping department.  Silvia's ability to build
							strong client relationships and identify their needs has been instrumental to her success."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Jacob_B.png"
						name="Jacob Bennett"
						position="Data Science"
						bio="Jacob brings an eclectic background to Leaf and the analytics team. Over the past decade he's worked on
							projects ranging from developing IoT devices to grow and harvest algae in Montana, to consulting on data
							and analytics best practices for oil and gas startups."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Andreas_K.png"
						name="Andreas Kull"
						position="Engineering"
						bio="Andreas' passion is to design and implement scalable architectures for distributed applications with state-
							of-the-art technologies for long-term and large-scale projects, especially in the financial and automotive
							sector. He feels very much at home in Asia and says that Japanese culture and aesthetics are the
							underlying motives in his work. He strongly believes in simplicity, liberty and professional commitment."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Matt_M.png"
						name="Matt Meyer"
						position="Customer Success"
						bio="Matt comes to Leaf with 11 years experience in the logistics and supply chain industry, where he has
							scaled teams and driven operational excellence at large 3PLs.  His dedication to reducing supply chain
							friction and improving network synergies can be applied to help carriers and shippers best utilize the Leaf
							Platform."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="David_S.png"
						name="David Sawyer"
						position="Customer Success"
						bio="David Sawyer is a member of the Customer Success Team at Leaf Logistics where he is responsible for
							building partnerships with carriers and logistics providers.  Prior to joining Leaf, David was a Vice
							President at Linx Partners, an industrial private equity firm with a focus on investing in transportation and
							logistics companies.  While at Linx, David worked in investment origination and transaction execution.
							He also served on the boards of directors of Linx’s transportation portfolio companies.  David lives in
							New York City, is a curious behavioral finance theorist, is captivated by stand-up comedy, has a love/hate
							relationship with the game of golf and is an avid New York Rangers fan."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Greer_L.png"
						name="Greer Lynch"
						position="Product Marketing Lead"
						bio='Greer has 15 years experience in technology marketing, including roles at Apple and Microsoft. She is an experienced
						entrepreneur who co-founded and led marketing for software company AnyMod. Most recently, Greer led Partnerships
						Marketing for logistics company Convoy. She holds an M.B.A. from the Kellogg School of Management and a B.A. from
						Barnard College, Columbia University.'
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Juan_M.png"
						name="Juan Mejia"
						position="Finance & Operations"
						bio="Juan brings over 7 years of experience leading finance and operations in companies ranging from a large media
						corporation to an early-stage tech company. Most recently, he led operations for a digital publisher where he was
						responsible for maximizing revenue opportunities and measuring success across the company's operations. Juan has a
						Bachelor's in Finance from Florida International University."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Margie_H.png"
						name="Margie Hamlin"
						position="Strategic Operations Lead"
						bio="Margie has spent her career prior to Leaf in management consulting. She was a key member of Kearney's Analytics
						practice for over six years, where she helped senior leaders solve operational problems and reduce supply chain costs
						across industries such as CPG, Retail, Finance, Entertainment and Chemicals. Margie holds a B.S.E. in Operations
						Research and Information Engineering from Cornell University."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Matt_S.png"
						name="Matt Strait"
						position="Customer Success"
						bio="Matt started in broadcast TV before transitioning into digital media and customer success. He has led highly
						successful teams for the past 10 years and enjoys working with customer success managers to help clients solve
						problems and grow their business. He holds a B.A. from Florida State University."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Nicole_N.png"
						name="Nicole Nicolitz"
						position="Operations Specialist"
						bio="Nicole joins the Leaf Logistics team with 10 years of supply chain and transportation experience. She has worked
						in a variety of leadership roles with highly respected 3PL organizations, the world’s largest oil field service company,
						and a prominent home furnishings ecommerce company. Serving in those roles has shaped her creative strategy to fine
						tune operational processes and has uniquely prepared her to help shift the shipper and carrier dynamic from strained to symbiotic."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Matthew_T.png"
						name="Matthew Testa"
						position="Customer Success"
						bio="Matthew brings customer/carrier relationship management experience from both an established 3PL, and a
						logistics/transportation startup. In his carrier management role, he successfully managed a carrier database of over 300 carriers,
						while securing over 2000 loads annually. While working in customer management, Matthew collaborated with various produce/non
						produce shippers to effectively create strategy plans, monitor seasonality of product, increase overall productivity,
						and maximize profits."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Nikola_D.png"
						name="Nikola Đorđević Togoev"
						position="Engineering - UI / UX Designer"
						bio="Nikola is a Senior product designer, specialized in designing clean user interfaces and creating outstanding digital
						experiences through research and data analysis. Prior to Leaf Logistics he developed several successful mobile and
						desktop applications in the field of education, entertainment and logistics. In his career he held positions of UX Team
						Lead and Frontend Team Manager. He holds a MFA degree from Faculty of design, Bauhaus University Weimar."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Ross_O.png"
						name="Ross Otto"
						position="Product Lead"
						bio="Ross has 10 years of experience in product strategy, design and development.
						He has developed and managed successful SaaS and data products for use by Fortune 500 companies
						in the U.S. and globally. Most recently, he built and launched an innovative AI product to disrupt
						the advertising industry. He is passionate about applying lean product thinking and machine learning
						(ML) to bring new product ideas to market. Ross holds a BBA in Management and Human Resources from the University of Wisconsin - Madison."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Matt_C.png"
						name="Matt Collier"
						position="Recruiting and People Lead"
						bio="Matt has 15 years’ diverse experience in people operations, organization design, and recruiting
						across a broad array of industries and growth stages. Over his career, he has built several high-performing
						People and Talent functions for early-stage ventures. Matt spent the last 5 years in consulting, most
						recently in the freight tech space. He is passionate about empowering people, forging teams, and designing
						enabling systems that drive optimal organizational performance and value."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Sean_G.png"
						name="Sean Gordon"
						position="Customer Success"
						bio="Sean was born and raised in the Chicagoland area.  After earning both Bachelor’s/Master’s degrees from the University
						of Illinois at Urbana-Champaign, as well as coaching the women’s basketball program, he made his way back to the city
						and began his journey within the logistics industry.  He worked as an Executive Carrier Sales rep
						on Coyote Logistics’ “East” sales team for just under three years. "
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="David_Knight.png"
						name="David Knight"
						position="Data Science"
						bio="David is a Miami native with a multidisciplinary background spanning web development, data science, and law
						(including service as an Army JAG officer in Iraq). Prior to joining Leaf, he spent six years in consulting, developing
						data analytics and machine learning solutions for organizations in the legal and government sectors."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="PJ_Benoit.png"
						name="PJ Benoit"
						position="Sales Lead"
						bio="Bringing 30 years of transportation experience to Leaf, PJ joins as the Sales Lead.  Prior to Leaf, PJ was at Convoy as
						the Business Development Director and Global Account Director at DB Schenker.  He lives in Chicagoland with his
						author-wife, Cara Lockwood, their combined family of five awesome children, and one adorably large dog, Teddy.  In
						his spare time, PJ enjoys co-hosting his Podcast, 'What the Parent?', being part of a championship-winning
						competitive BBQ team, going to all sorts of concerts, and traveling the world with his wife, finding the best local
						gems to visit for food and drink."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Kayla_Bhimull.png"
						name="Kayla Bhimull"
						position="Customer Success Manager"
						bio="Kayla is passionate about delivering exceptional customer experiences. With her deep background in logistics,
						operations management, and relationship building, she can anticipate the needs of her customers and ensure they
						maximize their investment and realize it’s full benefits. She loves to hike, travel, and has a passion for cooking."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Beverly_Vicil.png"
						name="Beverly Vicil"
						position="Finance and Operations Manager"
						bio="Beverly brings over 10 years of experience in Financial Operations. She comes to us from a health tech startup where
						she led financial operations from the ground up, implemented systems and processes that brought them closer to
						IPO readiness. Beverly calls NYC her home, where she lives with her husband, 3 boys and dog Hunny. She has a B.A. in
						Political Science with a minor in Economic and Public Policy From The City College of New York."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Andrew_Teal.png"
						name="Andrew Teal"
						position="Strategic Account Manager"
						bio="Andrew has experience as a frontline leader in planning operations for top 5 national carriers, with customer-facing
						leadership experience in supply chain and quality analytics, brokerage sales, freight planning and project
						management for major international and national companies. Andrew’s broad experience in all aspects has served
						him well in his 20+ year transportation career, which began after his service and leadership in the U.S. Navy’s
						Cryptologic and Intelligence communities as a non-commissioned officer. He also holds a bachelor’s degree in
						Business Management from Concordia University."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Jay_Fenster.png"
						name="Jay Fenster"
						position="Marketing Manager"
						bio="Passionate about the puzzle of problem solving, Jay is a writer, content marketer, and recovering startup founder
						who thrives at the intersection of customer and product. He comes to Leaf by way of Las Vegas, where he spent over
						a decade building marketing programs for iconic hospitality brands like Caesars and Hard Rock. Jay now resides in
						Brooklyn, where he enjoys yoga, running, and raving. Jay holds a B.S. in Commerce from the University of Virginia."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Fernando_Salomon.png"
						name="Fernando Salomon"
						position="Leaf Execution"
						bio="Recently graduated from International Business and Administration from Panamericana University in Guadalajara,
						 Mexico. Comes from the digital industry, having worked in a 3D printers business, and
						 in a manufacturer and inspection business as well. Driven by interconnection of people and ideas, and making processes and tasks in a more
						 efficient way."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Dario_Calle.png"
						name="Dario Calle"
						position="Leaf Execution"
						bio="Three years of experience as an IT service desk; raised in Texas living in Guadalajara.  He loves to combine his
						previous learnings to upgrade day by day; he sees himself as a problem solver on a daily basis.  Since he is a medical
						student, he loves helping others."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Horatiu_Bota.png"
						name="Horatiu Bota"
						position="Data Science"
						bio="Horatiu has over seven years of experience in data analytics and data science. Before joining Leaf, he worked with
						several startups in the UK on adding state-of-the-art text analytics to their products and wrote a book about handling
						accounting data with Python. He has a B.Sc. and a Ph.D. in Computing Science."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Katie_Steates.png"
						name="Katie Steates"
						position="People Partner/Business Recruiter"
						bio="Katie has extensive talent acquisition experience from her various roles prior to Leaf. She worked at Boston Consulting Group for over six years, where she was
						responsible for driving strategy, implementing streamlined recruiting processes and converting top talent from a wide range of MBA campuses. Katie holds a
						Bachelor's in Psychology and Communication from Hamilton College."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Perla_Roman.png"
						name="Perla Roman"
						position="Finance Associate"
						bio="Perla brings over 4 years of Accounts Payable and Accounts Receivables experience from a well known Spanish-language digital media company. She led
						relationships with key vendors, payables processes, assisted with the integration of new businesses, and was a crucial partner between Finance, Legal, and all
						Operational Leads. Born from Dominican parents, she resides in Little Dominican Republic in Uptown New York, Washington Heights. She has a B.A. in Human
						Services with a minor in Interdisciplinary Studies from the City College of New York."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Stephania_Parra.png"
						name="Stephania Parra"
						position="Leaf Execution"
						bio="Stephanie Parra currently resides in Jalisco Mexico. She graduated with a degree in international trade from the University of
						Guadalajara with 8 years of experience in sales and customer service and one year of experience as an international IT recruiter.  She is passionate about learning new things and is
						currently studying baking."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Jamel_Augustus.png"
						name="Jamel Augustus"
						position="Leaf Execution"
						bio="Jamel was born and raised in New York City, but currently resides in Chicago, IL. After spending a few years in the sports industry, Jamel transitioned into the
						Logistics industry where he worked on the customer side with some of the top 3PL organizations. He enjoys live music, attending sporting events, watching
						soccer, and is a big New York Mets fan."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Carolina_Gomez.png"
						name="Carolina Gomez"
						position="Data & Analytics"
						bio="Carolina is a supply chain professional with experience in technology in the freight and logistics space, global trade strategy, and lean six sigma. She has
						previous experience at a hyper growth startup, as well as corporate consulting. Carolina grew up in Nicaragua and speaks 4 languages. She holds an MBA from
						Georgia Institute of Technology."
					/>
				</Grid.Column>
			</Grid>
		</section>
		<section className="about-us-page__employees-section">
			<GreenDotHeading as='h2' className="other-pages__h2 about-us-page__employees-heading">
				Advisors
			</GreenDotHeading>
			<Grid textAlign="left">
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Mark_S.png"
						name="Mark Shaughnessy"
						bio="Mark is an experienced executive, formerly COO at Rubicon,
							and SVP and CPO at Coca Cola Refreshments. Mark started his career
							as a commodity trader at Cargill and Mars, and now advises early
							stage companies on strategy and growth."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Edwin_M.png"
						name="Edwin Marcial"
						bio="Edwin has 25 years of experience leading technology teams,
							and architecting and developing software systems. As the founding
							Chief Technology Officer for the Intercontinental Exchange (ICE),
							Edwin’s technical vision led to the design of an Internet based
							commodity trading system that would evolve to become
							the ICE trading platform."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Jim_M.png"
						name="Jim Molzon"
						bio="Jim is a senior advisor to logistics service providers and investors.
							Currently the lead advisor for logistics and supply chain at TPG,
							Jim previously held supply chain leadership roles at Lenovo,
							Solectron (Flex) and AlliedSignal (Honeywell)."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Edith_S.png"
						name="Edith Simchi-Levi"
						bio='Edith is a renowned leader in the application of technology
							in the supply chain. As a co-founder and senior executive at companies like
							Opalytics and LogicTools, Edith has been a practitioner, applying principles
							she wrote about in "Designing and Managing the Supply Chain" and other publications.'
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Joseph_T.png"
						name="Joseph M. Thompson"
						bio='Joe has led procurement and supply chain teams at multinationals such as Avon,
							Mars and Cargill. A former commodities trader and Chief Procurement Officer,
							Joe brings extensive experience in applying data and market tools to help manage
							complex, business critical operations.'
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Kalpana_R.png"
						name="Kalpana Raina"
						bio='Kalpana is a senior executive with global expertise in finance, strategy, operations
							and cross-cultural management. In addition to her leadership roles at BNY Mellon,
							Kalpana brings extensive public sector board experience to Leaf.'
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={6}>
					<Employee
						imgSrc="Thomas_W.png"
						name="Thomas Weill"
						bio='Tom brings extensive supply chain, procurement and operational experience to Leaf. Most recently,
							Tom held responsibilities including VP, Supply Chain Strategic Initiatives and Chief Procurement
							Officer at The Campbell Soup Company.'
					/>
				</Grid.Column>
			</Grid>
		</section>
	</Container>
);

export default Employees;
