import React from 'react';
import { Responsive as SemanticUiResponsive } from 'semantic-ui-react';

const OnMobile = ({ content, children }) => (
	<SemanticUiResponsive as={content} {...SemanticUiResponsive.onlyMobile}>
		{children}
	</SemanticUiResponsive>
);

const OnTablet = ({ content, children }) => (
	<SemanticUiResponsive as={content} {...SemanticUiResponsive.onlyTablet}>
		{children}
	</SemanticUiResponsive>
);

const OnComputer = ({ content, children }) => (
	<SemanticUiResponsive as={content} {...SemanticUiResponsive.onlyComputer}>
		{children}
	</SemanticUiResponsive>
);

class Responsive extends React.PureComponent {
	static OnMobile = OnMobile;
	static OnTablet = OnTablet;
	static OnComputer = OnComputer;

	render() {
		const { mobile, tablet, computer } = this.props;

		return (
			<>
				{mobile && <Responsive.OnMobile content={mobile} />}
				{tablet && <Responsive.OnTablet content={tablet} />}
				{computer && <Responsive.OnComputer content={computer} />}
			</>
		);
	}
};

export default Responsive;
