import React from 'react';

import { Container, Grid, DeorphanedText } from '../../../Common/Components';

import './_Message.scss';

const Message = () => (
	<Container as="section" className="careers-page__message" hasMaxWidth hasPadding>
		<Grid textAlign="center">
			<Grid.Column mobile={12} tablet={10} computer={8}>
				<p className="other-pages__body-text careers-page__message-text">
					<DeorphanedText>
						We are early stage, well funded by leading VCs, and growing.
						Our team has experience in transportation and supply chain, 
						analytics, modern and scalable enterprise software, and financial technology, 
						leveraging previous roles in the industry, at startups, and at consultancies. 
						We are on a mission to change the way B2B transportation is bought and sold. 
						We love solving hard problems - while not forgetting to have fun along the way. 
						We value diversity and inclusion in all forms and strive to foster respect, 
						integrity, and open communication.
					</DeorphanedText>
				</p>
			</Grid.Column>
		</Grid>
	</Container>
);

export default Message;
