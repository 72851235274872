import React from 'react';

import { Responsive } from '../../../../Common/Components';
import { FooterDesktop, FooterMobile } from './Features';

import './Footer.scss';

const Footer = ({ hasNavigation }) => {
	if (!hasNavigation) {
		return null;
	}
	
	return (
		<footer className="footer">
			<Responsive
				mobile={FooterMobile}
				tablet={FooterDesktop}
				computer={FooterDesktop}
			/>
		</footer>
	);
};

export default Footer;
