import React from 'react';
import classNames from 'classnames';

import './Container.scss';

const Container = ({
	as: As = 'div',
	hasPadding,
	hasMaxWidth,
	isCentered,
	isStreched,
	isFullScreen,
	isHalfScreen,
	className,
	children,
}) => (
	<As
		className={classNames('container', {
			'container--padding': hasPadding,
			'container--max-width': hasMaxWidth,
			'container--centered': isCentered,
			'container--streched': isStreched,
			'container--fullscreen': isFullScreen,
			'container--halfscreen': isHalfScreen,
		}, className)}
	>
		{children}
	</As>
);

export default Container;
