import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './_Hero.scss';

const Hero = () => (
	<Container as="section" className="contact-page__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<GreenDotHeading as='h2' className="other-pages__title contact-page__title">
				Get in touch with Leaf
			</GreenDotHeading>
		</Container>
	</Container>
);

export default Hero;
