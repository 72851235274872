import React from 'react';

import { Layout, ContactSection } from '../../Features';
import { Container, WaveSeparator, provideDeviceType } from '../../Common/Components';
import { Hero, Message, Benefits, Screenshots } from './Components/_index.js';

import './_WhyLeafPage.scss';

const WhyLeafPage = () => (
	<Layout className="why-leaf-page">
		<Hero />
		<Container className="why-leaf-page__radial-background">
			<Message />
			<WaveSeparator className="why-leaf-page__wave-separator" isUpsideDown />
		</Container>
		<Benefits />
		<Screenshots />
		<ContactSection />
	</Layout>
);

export default provideDeviceType(WhyLeafPage);
