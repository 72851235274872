import React from 'react';
import classNames from 'classnames';

export const sizes = {
	sm: {
		height: '16px',
		width: '51px',
	},
	base: {
		height: '24px',
		width: '76px',
	},
	md: {
		height: '32px',
		width: '102px',
	},
};

const leWhiteColor = '#FFFFFF';
const leMalachiteGreen = '#1DD264';

export default ({ size = sizes.base, className }) => (
	<div className={classNames('logo', className)}>
		<svg width={size.width} height={size.height} viewBox="0 0 257.9 79.9" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xmlSpace="preserve">

			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path fill={leWhiteColor} d="M0.1,0h15.4v79.9H0.1C0.1,79.9,0.1,0,0.1,0z"/>
				<path fill={leWhiteColor} d="M68.6,70.1c-2.5,3.1-5.6,5.6-9.3,7.3c-3.8,1.7-7.7,2.6-11.7,2.6c-3.8,0-7.4-0.6-10.8-1.8
		c-3.4-1.2-6.3-3-8.8-5.3s-4.5-5-5.9-8.3c-1.4-3.2-2.2-6.8-2.2-10.8s0.7-7.6,2.2-10.8s3.4-6,5.9-8.3s5.4-4.1,8.8-5.3
		c3.4-1.2,7-1.8,10.8-1.8c3.6,0,6.8,0.6,9.7,1.8s5.4,3,7.4,5.3s3.6,5,4.7,8.3c1.1,3.2,1.6,6.8,1.6,10.8v4.8H35.3
		c0.6,2.9,1.9,5.3,4,7s4.6,2.6,7.6,2.6c2.5,0,4.7-0.6,6.4-1.7s3.3-2.6,4.6-4.4L68.6,70.1z M55.6,48.2c0.1-2.6-0.8-4.8-2.6-6.7
		c-1.8-1.8-4.1-2.8-6.9-2.8c-1.7,0-3.2,0.3-4.5,0.8s-2.4,1.2-3.3,2.1c-0.9,0.9-1.6,1.8-2.2,3c-0.5,1.1-0.8,2.3-0.9,3.5L55.6,48.2
		L55.6,48.2z"/>
				<path fill={leWhiteColor} d="M109.2,72.5H109c-1.7,2.7-4,4.6-6.8,5.7c-2.8,1.2-5.8,1.7-9,1.7c-2.3,0-4.6-0.3-6.7-1s-4.1-1.6-5.7-2.9
		c-1.6-1.3-2.9-2.9-3.9-4.8s-1.4-4.1-1.4-6.7c0-2.9,0.5-5.3,1.6-7.3s2.5-3.6,4.3-4.9s3.9-2.3,6.2-3s4.7-1.2,7.2-1.6
		c2.5-0.3,5-0.5,7.5-0.6s4.8-0.1,6.9-0.1c0-2.7-1-4.9-2.9-6.5s-4.3-2.4-6.9-2.4c-2.5,0-4.8,0.5-6.9,1.6c-2.1,1.1-3.9,2.5-5.6,4.4
		l-8.2-8.4c2.9-2.7,6.2-4.7,10.1-6c3.8-1.3,7.8-2,11.9-2c4.5,0,8.2,0.6,11.1,1.7s5.2,2.8,7,5c1.7,2.2,3,4.9,3.6,8.1
		c0.7,3.2,1,6.9,1,11V80h-14.2L109.2,72.5L109.2,72.5z M105.4,56.9c-1.2,0-2.6,0.1-4.4,0.2c-1.7,0.1-3.4,0.4-5,0.9s-3,1.2-4.1,2.2
		s-1.7,2.3-1.7,4c0,1.8,0.8,3.2,2.4,4.1s3.2,1.3,4.9,1.3c1.5,0,3-0.2,4.4-0.6s2.6-1,3.7-1.7c1.1-0.8,2-1.7,2.6-2.9
		c0.6-1.2,1-2.5,1-4.1V57L105.4,56.9L105.4,56.9z"/>
				<path fill={leWhiteColor} d="M137.9,41.3h-10.2V29h10.2v-8.8c0-2.7,0.2-5.3,0.7-7.7s1.4-4.6,2.8-6.4c1.4-1.8,3.3-3.3,5.9-4.4
		c2.6-1.1,5.9-1.6,10.1-1.6c1.6,0,3.1,0.1,4.6,0.2c1.5,0.1,3,0.4,4.4,0.7l-0.7,13c-0.9-0.3-1.7-0.6-2.5-0.8s-1.6-0.3-2.6-0.3
		c-2.5,0-4.3,0.5-5.5,1.6s-1.8,3.2-1.8,6.5v7.9h11.4v12.3h-11.4v38.7h-15.4V41.3L137.9,41.3z"/>
				<g>
					<path fill={leWhiteColor} d="M257.9,0c0,0-29,0.1-40.1,0.1c-22.1,0-39.9,17.9-39.9,39.9c0,5.2,0,5.3,0,13.1l15.9-4.8c0-5.6,0-4.6,0-8.3
			c0-15.3,8.7-24.4,24.1-24.4c6.3,0,15,0,24.3,0c0,9.3,0.3,18.1,0.3,24.4c0,15.3-9.2,24.3-24.6,24.3c-3.1,0-2.9,0-8.3,0l-4.7,15.6
			c7.8,0,8.6-0.1,13.1-0.1c22.1,0,39.9-17.9,39.9-39.9C257.9,29,257.9,0,257.9,0z"/>
					<polygon fill={leMalachiteGreen} points="178,61 178,79.9 197,79.9 209.2,48.7"/>
				</g>
			</g>
		</svg>
	</div>
);


