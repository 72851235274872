import React from 'react';

import { Container, Grid, DeorphanedText, } from '../../../Common/Components';

import './_Message.scss';

const Message = () => (
	<Container as="section" className="about-us-page__message" hasMaxWidth hasPadding>
		<Grid textAlign="center">
			<Grid.Column mobile={12} tablet={10} computer={8}>
				<p className="other-pages__body-text about-us-page__message-text">
					<DeorphanedText>
						Leaf is a fast-paced, well funded startup focused on transforming transportation
						logistics through new digital solutions. We use predictive analytics and AI to
						identify the best transportation contracting opportunities for our customers, lowering
						their costs, improving their operations, and reducing their carbon footprint. Leaf’s
						unique Flex forward contracts introduce an exciting new way for the industry to
						rethink the way they manage their transportation.
					</DeorphanedText>
				</p>
			</Grid.Column>
		</Grid>
	</Container>
);

export default Message;
