import React from 'react';
import classNames from 'classnames';

import './Card.scss';

const Card = ({ as: As = 'div', hasPadding = true, isClickable, onClick, className, children }) => (
	<As
		className={classNames('le-card', {
			'le-card--padding': hasPadding,
			'le-card--clickable': isClickable,
		}, className)}
		onClick={onClick}
	>
		{children}
	</As>
);

export default Card;
