import React from 'react';

import { Container, Grid, Icons, WaveSeparator, GreenDotHeading, withDeviceType} from '../../../Common/Components';
import Card from './_Card';

import './_Benefits.scss';
import './_Cards.scss';

const Benefits = () => (
	<Container as="section" className="landing-page__cards">
		<Container hasMaxWidth hasPadding>
			<Grid textAlign="center">
				<Grid.Column mobile={12}>
					<GreenDotHeading as='h2' className="other-pages__h2 free-opportunity-assessment-page__benefits-subtitle">
						Make Leaf work for you
					</GreenDotHeading>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.RatesSvg}
						// title="Analyze your network data"
						text="We’ll analyze your network data and
						identify opportunities for securing
						capacity and cost savings."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.DataChartSvg}
						// title="Gives you the tool to build"
						text="The Leaf platform gives you the tools to
						build and manage a transportation plan
						that adapts to your needs."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.DataDrivenSvg}
						// title="Planned and scheduled loads"
						text="Tap into our network for planned and
						scheduled loads that save shippers time
						and money, and allow carriers to keep
						their trucks running full."
					/>
				</Grid.Column>
			</Grid>
		</Container>
		<WaveSeparator className="free-opportunity-assessment-page__benefits-wave-separator" />
	</Container>
);

export default withDeviceType(Benefits);
