import React from 'react';

import { DeorphanedText, InternalLink } from '../../../Common/Components';
import { locations } from '../../../Common/Constants';

const Benefit = ({ icon: Icon, text, linkData }) => (
	<article className="free-opportunity-assessment-page__benefit">
		<Icon className="free-opportunity-assessment-page__benefit-icon" />
		<p className="other-pages__body-text free-opportunity-assessment-page__benefit-text">
			<DeorphanedText>
				{text}
			</DeorphanedText>
			{
				linkData &&
				(
					<InternalLink to={linkData.link}>
						{linkData.text}
					</InternalLink>
				)
			}
		</p>
	</article>
);

export default Benefit;
