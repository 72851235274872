import React from 'react';
import classNames from 'classnames';
import { Menu, Button } from 'semantic-ui-react';

import { Icon, IconSizes } from '../../../../../../../Common/Components';

import './NavMenuMobileSubmenu.scss';

class NavMenuMobileSubmenu extends React.PureComponent {
	render() {
		const { name, openedSubmenu, handleToggleSubmenuClick, children } = this.props;
		const isSubmenuOpened = openedSubmenu === name;

		return (
			<div className="nav-menu-mobile__submenu">
				<Button
					className="nav-menu-mobile__submenu-control-button"
					basic
					content={name}
					labelPosition='right'
					icon={<Icon className="icon" name={`chevron-${isSubmenuOpened ? 'up' : 'down'}`} size={IconSizes.xs} />}
					onClick={() => handleToggleSubmenuClick(name)}
				/>
				<Menu
					className={classNames('nav-menu-mobile__collapsible-menu', {
						'nav-menu-mobile__collapsible-menu--opened': isSubmenuOpened,
					})}
					vertical
				>
					{children}
				</Menu>
			</div>
		);
	}
};

export default NavMenuMobileSubmenu;
