import React from 'react';

import { DeorphanedText } from '../../../Common/Components';

import './Text.scss';

const Text = ({ children }) => (
	<p className="other-pages__body-text single-career-template__text">
		<DeorphanedText>
			{children}
		</DeorphanedText>
	</p>
);

export default Text;
