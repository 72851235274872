import React from 'react';

import {Container, Grid, Icons, WaveSeparator, withDeviceType} from '../../../Common/Components';
import Card from './_Card';
import SeeMoreBenefitsLink from './_SeeMoreBenefitsLink';
import Video from './_Video';

import './_Cards.scss';

export const iFrameHeights = {
	mobile: '170',
	tablet: '356',
	desktop: '690'
};

const getiFrameHeight = (deviceType) => {
	if (deviceType.isMobile) {
		return iFrameHeights.mobile;
	}

	if (deviceType.isTablet) {
		return iFrameHeights.tablet;
	}

	return iFrameHeights.desktop;
};

// const renderVideo = deviceType => <Video iframe={getiFrame(deviceType)} />;
// const getiFrame = deviceType => `<iframe src="https://www.youtube-nocookie.com/embed/7QHIPAXst7c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

const Cards = ({ deviceType }) => (
	<Container as="section" className="landing-page__cards">
		<Container hasPadding hasMaxWidth>
			<Grid textAlign="center">
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.RatesSvg}
						title="Make data-driven decisions"
						text="Manage your transportation with agility
						using the Leaf platform. Data-driven guidance
						helps you plan and optimize your transportation
						procurement through the inevitable changes your
						business and transport market goes through every year."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.ServiceSvg}
						title="Guarantee capacity & rates"
						text="Lock in future rates and capacity with
						Leaf Flex forward contracts. Operate within your
						current business constraints, while improving
						service and protecting margins."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={4}>
					<Card
						icon={Icons.DataDrivenSvg}
						title="Provide reliable service"
						text="Deliver unprecedented service levels and
						smooth your logistics operations. Leaf Flex
						contracts allow you to lock in guaranteed coverage
						from high quality providers, including incumbents,
						so you can maintain high service performance."
					/>
				</Grid.Column>
			</Grid>
      		<SeeMoreBenefitsLink />
 			{/* {!deviceType.isMobile && renderVideo(deviceType)} */}
		</Container>
 		{/* {deviceType.isMobile && renderVideo(deviceType)} */}
		<WaveSeparator className="landing-page__cards-wave-separator" />
	</Container>
);

export default withDeviceType(Cards);
