import React from 'react';

import {
	Container,
	Grid,
} from '../../../../../../Common/Components';
import {
	FooterMenu,
	FooterMenuWhyLeaf,
	FooterMenuCompany,
	FooterMenuLegal,
	FooterMenuFreeAssessments,
	FooterLogo,
	FooterAddressInformation,
	FooterCopyright,
	FooterSocial,
} from '../../Components';

import './FooterDesktop.scss';

const FooterDesktop = () => (
	<div className="footer-desktop">
		<section className="footer-desktop__top-part">
			<Container hasPadding hasMaxWidth>
				<Grid>
					<Grid.Column computer={3} tablet={12}>
						<FooterLogo />
					</Grid.Column>
					<Grid.Column computer={2} tablet={3}>
						<FooterMenuWhyLeaf as={FooterMenu} />
					</Grid.Column>
					<Grid.Column computer={2} tablet={3}>
						<FooterMenuFreeAssessments as={FooterMenu} />
					</Grid.Column>
					<Grid.Column computer={2} tablet={3}>
						<FooterMenuCompany as={FooterMenu} />
					</Grid.Column>
					<Grid.Column computer={2} tablet={3}>
						<FooterMenuLegal as={FooterMenu} />
					</Grid.Column>
				</Grid>
			</Container>
		</section>
		<section className="footer-desktop__bottom-part">
			<Container hasPadding hasMaxWidth isStreched>
				<Grid columns={2} verticalAlign="middle" isStreched hasPadding={false}>
					<Grid.Column>
						<FooterCopyright />
					</Grid.Column>
					<Grid.Column textAlign="right">
						<FooterSocial />
					</Grid.Column>
				</Grid>
			</Container>
		</section>
	</div>
);

export default FooterDesktop;
