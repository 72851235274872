import React from 'react';
import classNames from 'classnames';

import './Icon.scss';

export const sizes = {
	xxs: 'xxs',
	xs: 'xs',
	sm: 'sm',
	base: 'base',
	md: 'md',
	lg: 'lg',
	xl: 'xl',
	xxl: 'xxl',
	xxxl: 'xxxl',
	xxxxl: 'xxxxl',
};

const Icon = ({
	name,
	className,
	onClick,
	tooltip = undefined,
	size = sizes.base,
	isUpsideDown = false,
	isVerticallyAlignedMiddle = false,
	...props
}) => (
	<i
		{...props}
		className={classNames(className, {
			'le-icon': true,
			[`icon-${name}`]: true,
			[`le-icon-${size}`]: true,
			'le-icon--clickable': !!onClick,
			'le-icon--upside-down': isUpsideDown,
			'le-icon--align-middle': isVerticallyAlignedMiddle,
		})}
		title={tooltip}
		onClick={onClick}
		onKeyPress={onClick}
	/>
);

export default Icon;
