import React from 'react';
import classNames from 'classnames';

const PopupGraphicSuccessSvg = ({ className }) => (
    <svg className={classNames(className)} width="80px" height="77px" viewBox="0 0 80 77" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Overlays" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Popup-3" transform="translate(-295.000000, -41.000000)">
                <g id="Group-41" transform="translate(2.000000, 1.000000)">
                    <g id="Group-39" transform="translate(103.000000, 40.000000)">
                        <g id="Group-38" transform="translate(190.000000, 0.000000)">
                            <path d="M53.079467,1.63424829e-13 C38.4819482,0.0107375101 26.6666667,11.7561553 26.6666667,26.2440953 L26.6666667,52.5062216 L53.0984299,52.5062216 C67.6706649,52.4770479 79.4814606,40.7419624 79.4918596,26.262734 C79.4918596,26.2585471 79.4919276,17.5043024 79.4920635,1.63424829e-13 L53.079467,1.63424829e-13 Z" id="Fill-1" fill="#BBF1D0"></path>
                            <g id="confetti" fill="#183B5A">
                                <path d="M78.0793499,36.4735559 C77.2075414,36.233283 76.4680733,36.7711842 76.1706164,37.5602093 C75.6599614,38.9147283 73.7391688,39.7248354 72.3495936,39.9065127 C70.2567274,40.1802687 68.2173541,39.2613413 66.3682975,38.4013194 C64.1747846,37.3813223 61.9140192,36.4078296 59.4480951,36.4286016 C56.9667106,36.4495286 54.5443847,37.4434832 52.5235637,38.8407862 C50.2487294,40.4135659 48.4213173,42.4659613 46.6333291,44.5547852 C46.0878883,45.1920508 46.008886,46.12896 46.6333291,46.7550646 C47.1917565,47.3149779 48.2797006,47.3954306 48.8277696,46.7550646 C51.6987546,43.4005453 55.4826727,38.975339 60.4173037,39.584392 C62.7471769,39.8719444 64.8340134,41.1756185 66.9724876,42.0458713 C69.1706386,42.9404615 71.5818331,43.3631867 73.9115516,42.7493283 C76.2208625,42.1407404 78.301515,40.6719755 79.1628105,38.3872131 C79.4594944,37.6012882 78.833969,36.6814307 78.0793499,36.4735559 Z" id="Path"></path>
                                <path d="M31.8026928,20.1124855 C33.2654768,18.0733955 34.8246082,15.8954389 35.26981,13.3620147 C35.7016003,10.9044629 35.0700241,8.42953333 33.7099079,6.36034266 C32.1412188,3.97400849 29.8643383,2.25671677 27.6169014,0.565491646 C26.9470947,0.0615375899 25.8881987,0.456105067 25.5000345,1.12406141 C25.0378756,1.9194027 25.3838011,2.74949943 26.0551494,3.25469476 C29.5131714,5.85685408 33.8373947,9.47731711 31.7578335,14.3227547 C29.9108933,18.6259141 25.1055499,22.0871848 25.7062949,27.2483694 C25.9957995,29.7352461 27.4619749,31.9837998 29.9599148,32.6481875 C31.8871701,33.1606752 32.7091287,30.1572766 30.7826441,29.6447889 C29.2218171,29.2297405 28.6053482,27.5135349 28.7721447,26.0084997 C29.0146317,23.8232506 30.5591183,21.8460688 31.8026928,20.1124855 Z" id="Path"></path>
                                <path d="M54.2446624,23.6271678 C53.9937221,21.5617207 52.8296551,19.6910219 51.0901826,18.5547319 C50.1209488,17.9217224 49.0664754,17.5885758 47.9967422,17.5032766 C48.5257515,15.7519347 49.5159483,14.1427065 50.9634793,13.0288636 C51.6256657,12.5193908 51.977722,11.6913233 51.5185383,10.9030415 C51.1373495,10.2485136 50.0693118,9.83208035 49.4018846,10.3455782 C46.9322497,12.2460001 45.2827951,14.9717029 44.6918214,17.984884 C42.6724302,18.7225437 40.8765424,20.2122602 39.8416448,22.0626791 C37.0600302,27.0358831 38.8176913,32.8496941 41.6164155,37.3707042 C42.6656481,39.0655412 45.3435264,37.50709 44.2881282,35.8023453 C42.4626454,32.8534095 40.9238634,29.3180613 41.7209226,25.7783785 C42.1078146,24.0606299 43.0890713,22.4506277 44.4905143,21.4971658 C44.6255411,23.2022201 45.102451,24.8857561 45.9321879,26.3805813 C47.0839237,28.4553168 49.2702488,30.1777096 51.6859346,29.0175792 C53.6361168,28.0811461 54.4929824,25.6721803 54.2446624,23.6271678 Z M50.8010155,25.7698641 C49.9589474,26.9765917 49.0741824,25.6607245 48.6039006,24.8119127 C47.963602,23.6562717 47.6172489,22.3387016 47.5554387,21.023918 C47.549119,20.8876871 47.5483483,20.7505273 47.5474234,20.6132127 C47.6237228,20.6163088 47.7000222,20.6197146 47.7770924,20.6262165 C50.0443411,20.8174043 52.2453095,23.7000823 50.8010155,25.7698641 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M60.5244468,27.9862209 C58.5030293,27.9862209 58.4995861,31.0208713 60.5244468,31.0208713 C62.5458643,31.0207202 62.5491509,27.9862209 60.5244468,27.9862209 Z" id="Path"></path>
                                <path d="M62.5474884,23.6028369 C60.5260708,23.6028369 60.5227842,26.6374873 62.5474884,26.6374873 C64.5689059,26.6374873 64.572349,23.6028369 62.5474884,23.6028369 Z" id="Path"></path>
                                <path d="M65.5821682,27.6490375 C63.5607115,27.6490375 63.5574249,30.6836879 65.5821682,30.6836879 C67.6036248,30.6836879 67.6069115,27.6490375 65.5821682,27.6490375 Z" id="Path"></path>
                                <path d="M13.9930811,14.8360689 C11.9717418,14.8360689 11.9682988,17.8707194 13.9930811,17.8707194 C16.0145769,17.8707194 16.0178634,14.8360689 13.9930811,14.8360689 Z" id="Path"></path>
                                <path d="M15.6789393,10.4526849 C13.6576,10.4526849 13.6543135,13.4873354 15.6789393,13.4873354 C17.7004352,13.4871842 17.7038781,10.4526849 15.6789393,10.4526849 Z" id="Path"></path>
                                <path d="M18.7136778,14.1617021 C16.6922212,14.1617021 16.6889345,17.1963526 18.7136778,17.1963526 C20.7351344,17.1963526 20.7384211,14.1617021 18.7136778,14.1617021 Z" id="Path"></path>
                                <path d="M43.7174561,53.7257902 L23.1519021,33.1603421 C22.7998029,32.8083991 22.3000443,32.646854 21.8083089,32.7269322 C21.3168822,32.8068561 20.8936534,33.1180658 20.6713156,33.5636649 L18.8222549,37.266396 L0.16315901,74.6373182 C-0.134474338,75.2333533 -0.0175193985,75.9529774 0.453694829,76.4241892 C0.750865295,76.7215124 1.14709258,76.8778116 1.54902875,76.8778116 C1.7838644,76.8778116 2.02055157,76.8244261 2.24026639,76.7145692 L43.3141313,56.2063639 C43.7597327,55.9840273 44.0710982,55.560955 44.1508683,55.0693765 C44.2309469,54.577798 44.0695553,54.0780419 43.7174561,53.7257902 Z M5.00567981,71.8719191 L11.6983413,58.4679954 L13.9579787,67.4021959 L5.00567981,71.8719191 Z M30.5626484,59.1113986 L27.925762,52.6849275 C27.6009729,51.8937113 26.6966551,51.5153841 25.9052806,51.8400173 C25.1140604,52.1646504 24.7357312,53.0692722 25.060366,53.8604883 L27.78412,60.4986498 L16.7945217,65.9856289 L13.8188054,54.2206089 L20.0704943,41.6995517 L21.7907194,45.8920098 C22.0365099,46.4908221 22.6144957,46.8532572 23.2239575,46.8532572 C23.4197567,46.8532572 23.6187961,46.8159182 23.8112009,46.73692 C24.6024211,46.4122868 24.9807502,45.5076651 24.6561154,44.716449 L21.9082915,38.0193474 L22.4819571,36.8706336 L40.0072998,54.3958861 L30.5626484,59.1113986 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M72.9994347,50.5775076 C69.5602538,50.5775076 66.76231,53.3754514 66.76231,56.8146323 C66.76231,60.2545811 69.5602538,63.0532928 72.9994347,63.0532928 C76.4395371,63.0532928 79.2380952,60.2545811 79.2380952,56.8146323 C79.2380952,53.3754514 76.4395371,50.5775076 72.9994347,50.5775076 Z M72.9994347,59.9701329 C71.2603384,59.9701329 69.8454699,58.5544965 69.8454699,56.8146323 C69.8454699,55.0755359 71.260492,53.6606675 72.9994347,53.6606675 C74.739299,53.6606675 76.1549353,55.0755359 76.1549353,56.8146323 C76.1549353,58.5544965 74.7394525,59.9701329 72.9994347,59.9701329 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M71.0304852,6.66388178 L64.8188634,0.452383219 C64.529117,0.16279641 64.1362744,0 63.7266594,0 C63.3170444,0 62.9243557,0.16279641 62.6346093,0.452537091 L56.4245262,6.66403566 C55.8216444,7.26705941 55.8217982,8.24491497 56.4246801,8.8480926 L62.6347631,15.0580524 C62.9363579,15.3596413 63.3316625,15.5104357 63.7268133,15.5104357 C64.121964,15.5104357 64.5172686,15.3596413 64.8187095,15.0582063 L71.0303313,8.84824647 C71.3202316,8.55865966 71.4828774,8.16582485 71.4828774,7.75606413 C71.4827235,7.34645728 71.3199239,6.95362246 71.0304852,6.66388178 Z M63.727121,11.7821209 L59.7009844,7.75591026 L63.727121,3.72877638 L67.7543348,7.75591026 L63.727121,11.7821209 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M24.7829787,47.8800405 C22.7615221,47.8800405 22.7582354,50.914691 24.7829787,50.914691 C26.8044354,50.914691 26.807722,47.8800405 24.7829787,47.8800405 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default PopupGraphicSuccessSvg;

