import axios from 'axios';
import settings from '../Config'

export default class Api {
	static post(resource, data) {
		return this.request({
			resource,
			method: 'POST',
			data,
		});
	}

	static request({ resource, method, data }) {
		const config = {
			url: settings.baseUrl + resource,
			method,
			data,
		};

		return axios(config);
	}
}
