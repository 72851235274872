import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import './InternalLink.scss';

const InternalLink = ({ className, children, ...props }) => (
	<Link
		className={classNames('internal-link', className)}
		{...props}
	>
		{children}
	</Link>
);

export default InternalLink;
