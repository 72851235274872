import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './Hero.scss';

const Hero = ({ title }) => (
	<Container as="section" className="legal-page__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<h1 className="other-pages__h1 legal-page__h1">
				Legal
			</h1>
			<GreenDotHeading as='h2' className="other-pages__title legal-page__title">
				{title}
			</GreenDotHeading>
		</Container>
	</Container>
);

export default Hero;
