import React from 'react';

import { Card, GreenDotHeading, DeorphanedText } from '../../../Common/Components';

import './_Card.scss';

const LandingPageCard = ({ icon: Icon, title, text }) => (
	<Card as="article" className="landing-page__card">
		<Icon className="landing-page__card-icon" />
		<GreenDotHeading className="landing-page__card-title" as='h3'>
			{title}
		</GreenDotHeading>
		<p className="landing-page__card-text">
			<DeorphanedText>
				{text}
			</DeorphanedText>
		</p>
	</Card>
);

export default LandingPageCard;
