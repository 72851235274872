import React from 'react';
import classNames from 'classnames';

import './ExternalLink.scss';

const ExternalLink = ({ to, children, className, doOpenInSameTab = false, ...props }) => (
	<a
		className={classNames('external-link', className)}
		href={to}
		target={doOpenInSameTab ? '_self' : '_blank'}
		rel="noopener noreferrer"
		{...props}
	>
		{children}
	</a>
);

export default ExternalLink;
