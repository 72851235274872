import RatesSvg from './RatesSvg';
import ServiceSvg from './ServiceSvg';
import DataDrivenSvg from './DataDrivenSvg';
import ReduceAdministrationSvg from './ReduceAdministrationSvg';
import RefocusResourcesSvg from './RefocusResourcesSvg';
import SimplifyPaymentSvg from './SimplifyPaymentSvg';
import PhoneSvg from './PhoneSvg';
import NewsletterSvg from './NewsletterSvg';
import SignUpSvg from './SignUpSvg';
import QuoteSvg from './QuoteSvg';
import LinkedinSvg from './LinkedinSvg';
import TwitterSvg from './TwitterSvg';
import PopupGraphicSuccessSvg from './PopupGraphicSuccessSvg';
import PopupGraphicErrorSvg from './PopupGraphicErrorSvg';
import Graphic404Svg from './Graphic404Svg';
import PaperPlaneSvg from './PaperPlaneSvg';
import DataChartSvg from './DataChartSvg';
import SearchIconSvg from './SearchIconSvg';
import QuestionMarkSvg from './QuestionMarkSvg';
import SuccessBagSvg from './SuccessBagSvg';

export default {
	RatesSvg,
	ServiceSvg,
	DataDrivenSvg,
	ReduceAdministrationSvg,
	RefocusResourcesSvg,
	SimplifyPaymentSvg,
	PaperPlaneSvg,
	DataChartSvg,
	SearchIconSvg,
	QuestionMarkSvg,
	PhoneSvg,
	NewsletterSvg,
	SignUpSvg,
	QuoteSvg,
	LinkedinSvg,
	TwitterSvg,
	PopupGraphicSuccessSvg,
	PopupGraphicErrorSvg,
	Graphic404Svg,
	SuccessBagSvg,
};
