import React from 'react';

import { Container, Grid, GreenDotHeading, Responsive, DeorphanedText } from '../../../Common/Components';

import './_Screenshots.scss';

const Screenshots = () => (
	<section className="why-leaf-page__screenshots why-leaf-page__screenshots-wave-background">
		<Container hasPadding hasMaxWidth>
			<GreenDotHeading as="h2" className="other-pages__h2 why-leaf-page__screenshots-title">
				What Leaf technology can do for you
			</GreenDotHeading>
			<Grid>
				<Grid.Row className="why-leaf-page__screenshot" verticalAlign="middle" reversed="computer">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 why-leaf-page__screenshot-subtitle">
							Smarter contracting decisions
						</GreenDotHeading>
						<div className="other-pages__body-text why-leaf-page__screenshot-text">
							<DeorphanedText>
								Leaf’s advanced analytics engine uses historical shipment data,
								forward looking market data, and Leaf’s proprietary network
								insights into future market developments to automatically
								suggest best-fit contracting opportunities.
							</DeorphanedText>
						</div>
					</Grid.Column>
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="computer" />
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="why-leaf-page__screenshot-image why-leaf-page__screenshot-image--right"
							src="/images/screenshots/Automated_opportunity_identification.png"
							alt="automated opportunity identification"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="why-leaf-page__screenshot" verticalAlign="middle">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 why-leaf-page__screenshot-subtitle why-leaf-page__screenshot-subtitle--leaf-contract">
							Forward contracts with Leaf Flex 
						</GreenDotHeading>
						<div className="other-pages__body-text why-leaf-page__screenshot-text">
							<DeorphanedText>
								The Leaf Flex forward contract is a standardized, enforceable,
								and tradeable agreement for future transportation capacity at
								a fixed price for weeks or months at a time, based on your business
								needs. Shippers get peace of mind by delivering guaranteed, high-quality
								service to their customers, while logistics service providers gain
								a predictable, plannable schedule for their trucks and drivers. 
							</DeorphanedText>
						</div>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="why-leaf-page__screenshot-image"
							src="/images/screenshots/The_Leaf_Flex_Dedicated_contract.png"
							alt="the leaf forward contract"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="why-leaf-page__screenshot" verticalAlign="middle" reversed="computer">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 why-leaf-page__screenshot-subtitle why-leaf-page__screenshot-subtitle--leaf-index">
							Leaf Flex forward rate index
						</GreenDotHeading>
						<div className="other-pages__body-text why-leaf-page__screenshot-text">
							<DeorphanedText>
								With Leaf, you get access to forward-looking market indicators.
								Be the first to know how future rate and capacity movements
								will impact the market, and use your knowledge to make
								better contracting decisions.
							</DeorphanedText>
						</div>
					</Grid.Column>
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="computer" />
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="why-leaf-page__screenshot-image why-leaf-page__screenshot-image--right"
							src="/images/screenshots/Leaf_Forward rates_index.png"
							alt="leaf forward rates index"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</section>
);

export default Screenshots;
