import React from 'react';

import Segment from './Segment';
import Text from './Text';

const AboutCompany = () => (
	<Segment title="We are Leaf">
		<Text>
		Leaf is on a mission to upend the way companies buy and sell transportation. 
		Every year, billions of dollars, hundreds of megatons of carbon and millions of 
		person-hours are wasted because the companies who ship goods and the companies 
		who carry them aren’t upfront about their needs and abilities. Our platform finds 
		ways for buyers and sellers to contract binding commitments for plannable and 
		predictable freight and allows those contracts to be traded when needs change. 
		In time, Leaf will become the hub through which the trillion-dollar American freight 
		market is managed. 
		</Text>
		
		<Text>
		We are a team of experienced, smart, and mildly disagreeable troublemakers who like 
		taking on unreasonably big challenges; we know how to build things, we’ve lived 
		what’s broken in this industry, and we’re looking for bright, ambitious people to help 
		us drag the transportation world kicking and screaming into the 21st century. 
		</Text>
	</Segment>
);

export default AboutCompany;
