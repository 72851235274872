export default {
	index: '/',
	whyLeaf: '/why-leaf',
	about: '/about',
	contact: '/contact',
	notFoundPage: '/404',
	solutionsForShippers: '/solutions-for-shippers',
	solutionsForCarriers: '/solutions-for-carriers',
	solutionsForBrokers: '/solutions-for-brokers',
	careers: '/careers',
	careersSingle: '/careers/{0}',
	legalTermsAndConditions: '/terms-and-conditions',
	supplyChainDigital: '/supply-chain-digital',
	supplyChainDigitalDetails: '/supply-chain-digital/details',
	legalPrivacyPolicy: '/privacy-policy',
	freeOpportunityAssessment: '/get-started',
	//freeOpportunityAssessment: '/free-opportunity-assessment',
	// freeOpportunityAssessmentDetails: '/free-opportunity-assessment/details',
	gigabit: '/gigabit',
	gigabitDetails: '/gigabit/details',
	businessChief: '/business-chief',
	businessChiefDetails: '/business-chief/details',
	meeting: '/meeting',
}
