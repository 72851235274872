import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './_Hero.scss';

const Hero = () => (
	<Container as="section" className="free-opportunity-assessment-page__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<h1 className="other-pages__h1 free-opportunity-assessment-page__h1">
				Get Started
			</h1>
			<GreenDotHeading as='h2' className="other-pages__title free-opportunity-assessment-page__title">
				Let us save your time and money
			</GreenDotHeading>
			<GreenDotHeading as='h2' className="other-pages__title free-opportunity-assessment-page__title">
				Make Leaf work for you
			</GreenDotHeading>
		</Container>
	</Container>
);

export default Hero;
