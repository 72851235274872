import React from 'react';

import { Icons } from '../../../Common/Components'

import './_QuestionMarkTitle.scss'

const QuestionMarkTitle = ({ children }) => (
	<div className="question-mark-title">
		<Icons.QuestionMarkSvg className="question-mark-title__icon" />
		<h3 className="other-pages__h3 question-mark-title__text">{children}</h3>
	</div>
);

export default QuestionMarkTitle;
