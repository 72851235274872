import React from 'react';

import { locations } from '../../../../../Common/Constants';
import { InternalLink, Logo, LogoSizes, withDeviceType } from '../../../../../Common/Components';

import './FooterLogo.scss';

const getLogoSize = (deviceType) => {
	if (deviceType.isMobile) {
		return LogoSizes.base;
	}

	return LogoSizes.md;
};

const FooterLogo = ({ deviceType }) => (
	<InternalLink className="footer__logo" to={locations.index}>
		<Logo size={getLogoSize(deviceType)} />
	</InternalLink>
);

export default withDeviceType(FooterLogo);
