import React from 'react';
import classNames from 'classnames';

const LinkedinSvg = ({ className }) => (
    <svg className={classNames(className)} width="26px" height="25px" viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="icons" transform="translate(-262.000000, -109.000000)" stroke="#FFFFFF" strokeWidth="2">
                <path d="M279.8,117.2 C283.77645,117.2 287,120.42355 287,124.4 L287,132.8 L282.2,132.8 L282.2,124.4 C282.2,123.074517 281.125483,122 279.8,122 C278.474517,122 277.4,123.074517 277.4,124.4 L277.4,132.8 L272.6,132.8 L272.6,124.4 C272.6,120.42355 275.82355,117.2 279.8,117.2 Z M263,118.4 L267.8,118.4 L267.8,132.8 L263,132.8 L263,118.4 Z M265.4,114.8 C264.074517,114.8 263,113.725483 263,112.4 C263,111.074517 264.074517,110 265.4,110 C266.725483,110 267.8,111.074517 267.8,112.4 C267.8,113.725483 266.725483,114.8 265.4,114.8 Z" id="Linkedin"></path>
            </g>
        </g>
    </svg>
);

export default LinkedinSvg;
