import React from 'react';

import { locations } from '../../../../../../Common/Constants';
import { InternalLink } from '../../../../../../Common/Components';
import { FooterMenuItem } from '../';

const FooterMenuWhyLeaf = ({ as: As, ...props }) => (
	<As name="Why Leaf" {...props}>
		<FooterMenuItem>
			<InternalLink to={locations.whyLeaf}>Why Leaf</InternalLink>
		</FooterMenuItem>
	</As>
);

export default FooterMenuWhyLeaf;