import React from 'react';

import { formattingService } from '../Services';

const DeorphanedText = ({ children }) => (
	<React.Fragment>
		{formattingService.deorphanText(children)}
	</React.Fragment>
);

export default DeorphanedText;
