import slugifyCareer from '../../utilities/slugifyCareer';

const nonBreakSpaceChar = '\xa0';
const lastSpaceInString = new RegExp(/(\s)(?!.*\s)/);

class FormattingService {
	static deorphanText(text) {
		return text && text.replace(lastSpaceInString, nonBreakSpaceChar);
	}

	static slugifyCareer(career) {
		return slugifyCareer(career);
	}
}

export default FormattingService;
