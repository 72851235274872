import React from 'react';

import { Container, Grid, GreenDotHeading, Responsive } from '../../../Common/Components';

import './_Screenshots.scss';

const Screenshots = () => (
	<section className="free-opportunity-assessment-page__screenshots">
		<Container hasPadding hasMaxWidth>
			<Grid>
				<Grid.Row className="free-opportunity-assessment-page__screenshot" verticalAlign="middle">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 free-opportunity-assessment-page__screenshot-subtitle" hasThreeDots>
							Opportunities from your data
						</GreenDotHeading>
						<div className="other-pages__body-text free-opportunity-assessment-page__screenshot-text">
							Leaf suggests contracting opportunities from within your network to
							improve cost and service predictability.
						</div>
						<GreenDotHeading as='h3' className="other-pages__h3 free-opportunity-assessment-page__screenshot-subtitle" hasThreeDots>
							... and from combining your data with others'
						</GreenDotHeading>
						<div className="other-pages__body-text free-opportunity-assessment-page__screenshot-text">
							By overlaying your network with the vast amounts of other 
							demand data on our platform, Leaf identifies opportunities 
							attractive to carriers and helps you execute binding forward 
							contracts to deliver significant cost savings.
						</div>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="get-started-free-opportunity-assessment-page__screenshot-image"
							src="/images/screenshots/Free_Assessment_Chart.png"
							alt="free assessment chart"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="free-opportunity-assessment-page__screenshot" verticalAlign="middle" reversed="computer">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 free-opportunity-assessment-page__screenshot-subtitle">
							A network with exponentential opportunity
						</GreenDotHeading>
						<div className="other-pages__body-text free-opportunity-assessment-page__screenshot-text">
							Our nationwide network of shippers and logistics
							service providers multiplies the number of opportunities for network
							efficiency. Our advanced analytics engine runs numerous algorithms
							across our many-to-many network to find the best
							transportation options that minimize risk and save you money.
						</div>
					</Grid.Column>
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="computer" />
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="get-started-free-opportunity-assessment-page__screenshot-image get-started-free-opportunity-assessment-page__screenshot-image--right"
							src="/images/screenshots/Free_Assessment_Map.png"
							alt="free assessment map"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</section>
);

export default Screenshots;
