import React from 'react';

import { locations } from '../../../Common/Constants';
import { formattingService } from '../../../Common/Services';
import { GreenDotHeading, Grid, withDeviceType } from '../../../Common/Components';
import Career from './_Career';

import './_CareersDepartment.scss';

const getCareersCardsAlignment = (deviceType, numberOfCareers) =>
	deviceType.isTablet && numberOfCareers > 1 && numberOfCareers % 2 ? 'left' : 'center';

const CareersDepartment = ({ name, careers, deviceType }) => (
	<article className="careers-page__department">
		<GreenDotHeading as='h3' className="careers-page__department-heading">
			{name}
		</GreenDotHeading>
		<Grid textAlign={getCareersCardsAlignment(deviceType, careers.length)}>
			{careers.map(career => (
				<Grid.Column mobile={12} tablet={6} computer={4} key={career.id} textAlign="left">
					<Career
						title={career.title}
						office={career.office}
						link={`${locations.careers}/${formattingService.slugifyCareer(career)}`}
					/>
				</Grid.Column>
			))}
		</Grid>
	</article>
);

export default withDeviceType(CareersDepartment);
