import React from 'react';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';

import {
	Container,
	Button,
	Responsive,
	Checkbox,
	Grid,
	FormSuccessMessage,
	validators,
	WaveSeparator
} from '../../../Common/Components';
import { api } from '../../../Common/Services';
import { resources, locations } from '../../../Common/Constants';
import createNewContact from '../../../pages/contact/_contactBuilder';
import requestTypes from '../../../pages/contact/_requestTypes';

import './_RequestAssessment.scss';

const contactFormInitialValues = {
	doSubscribeToEmail: true,
};

class RequestAssessment extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subscribeSuccessMessage: undefined,
			subscribeErrorMessage: undefined,
			isFormDisabled: false,
			isFormSuccess: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values) {
		this.setState({
			isFormDisabled: true,
		});
		const contact = createNewContact({ data: values, requestType: requestTypes.freeOpportunityAssessment });

		api.post(resources.contactRequests, contact)
			.then(response => {
				this.setState({
					subscribeSuccessMessage: `Thanks for getting in touch, ${response.data.firstName}. We will contact you shortly.`,
					isFormSuccess: true,
				});
			})
			.catch(error => {
				this.setState({
					subscribeErrorMessage: error.message,
					isFormDisabled: false,
				});
			});
	}

	render() {
		const { isFormSuccess, isFormDisabled, subscribeSuccessMessage, subscribeErrorMessage } = this.state;

		return (
			<Container as="section">
				<Container className="request-assessment__form-wrapper" hasPadding isCentered>
				{ !isFormSuccess && <Form
						onSubmit={this.onSubmit}
						initialValues={contactFormInitialValues}
						render={({ handleSubmit, submitFailed, submitSucceeded }) => (
							<div>
								<h2 className="other-pages__h2 request-assessment__heading">
									Talk to an expert
								</h2>
								<form className="contact-form" onSubmit={handleSubmit}>
									<div className="contact-form__row">
										<Grid>
											<Grid.Column width={12} className="contact-form__form-message-wrapper">
												<div className="contact-form__form-error">
													{(submitFailed && errors.FORM_ERROR)|| (!submitFailed && submitSucceeded && !subscribeSuccessMessage && subscribeErrorMessage)}
												</div>
											</Grid.Column>
											<Field
												name="firstName"
												validate={validators.required}
												render={({ input, meta }) => (
													<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
														<input
															className={classNames('contact-form__input', {
																'contact-form__input--error': meta.error && meta.touched,
															})}
															type="text"
															placeholder="First name"
															disabled={isFormDisabled}
															{...input}
														/>
													</Grid.Column>
												)}
											/>
											<Field
												name="lastName"
												validate={validators.required}
												render={({ input, meta }) => (
													<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
														<input
															className={classNames('contact-form__input', {
																'contact-form__input--error': meta.error && meta.touched,
															})}
															type="text"
															placeholder="Last name"
															disabled={isFormDisabled}
															{...input}
														/>
													</Grid.Column>
												)}
											/>
											<Field
												name="email"
												validate={validators.combineValidators(validators.required, validators.email)}
												render={({ input, meta }) => (
													<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
														<input
															className={classNames('contact-form__input', {
																'contact-form__input--error': meta.error && meta.touched,
															})}
															type="email"
															placeholder="Work email"
															disabled={isFormDisabled}
															{...input}
														/>
													</Grid.Column>
												)}
											/>
											<Field
												name="phone"
												validate={validators.required}
												render={({ input, meta }) => (
													<Grid.Column className="contact-form__form-field" mobile={12} tablet={12} computer={6}>
														<input
															className={classNames('contact-form__input', {
																'contact-form__input--error': meta.error && meta.touched,
															})}
															type="text"
															placeholder="Phone"
															disabled={isFormDisabled}
															{...input}
														/>
													</Grid.Column>
												)}
											/>
											<Field
												name="message"
												render={({ input, meta }) => (
													<Grid.Column className="contact-form__form-field" width={12}>
												<textarea
													className={classNames('contact-form__textarea', {
														'contact-form__input--error': meta.error && meta.touched,
													})}
													type="text"
													placeholder="Message (optional)"
													disabled={isFormDisabled}
													{...input}
												/>
													</Grid.Column>
												)}
											/>
											<Field
												name="doSubscribeToEmail"
												type="checkbox"
												render={({ input }) => (
													<Grid.Column className="contact-form__form-field" width={12}>
														<Checkbox
															className="contact-form__checkbox"
															label="Yes, I want to be informed about relevant Leaf news occasionally."
															disabled={isFormDisabled}
															{...input}
														/>
													</Grid.Column>
												)}
											/>
											<Grid.Column className="contact-form__buttons" width={12} textAlign="center">
												<Button
													type="submit"
													isHighlighted
													hasLargePadding
													disabled={isFormDisabled}
												>
													Send message
												</Button>
											</Grid.Column>
										</Grid>
									</div>
								</form>
							</div>
						)}
					/>}
					{ isFormSuccess && <FormSuccessMessage message={subscribeSuccessMessage} backToLocation={locations.index} /> }
					</Container>
					<WaveSeparator className="request-assessment-page__wave-separator" />
			</Container>
		);
	}
}

export default RequestAssessment;
