import React from 'react';

import { locations } from '../../../Common/Constants';
import { Container, Grid, Icons, WaveSeparator, GreenDotHeading } from '../../../Common/Components';
import Benefit from './_Benefit';

import './_Benefits.scss';

const Benefits = () => (
	<Container as="section" className="free-opportunity-assessment-page__benefits">
		<Container hasMaxWidth hasPadding>
			<Grid textAlign="center">
				<Grid.Column mobile={12}>
					<GreenDotHeading as='h2' className="other-pages__h2 free-opportunity-assessment-page__benefits-subtitle">
						It couldn’t be simpler
					</GreenDotHeading>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.PaperPlaneSvg}
						text="Send us "
						linkData={{
							text: 'your data',
							link: `${locations.supplyChainDigitalDetails}`,
						}}
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.SearchIconSvg}
						text="We'll run it through our automated analytics."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.DataChartSvg}
						text="You'll get real, actionable results!"
					/>
				</Grid.Column>
			</Grid>
		</Container>
		<WaveSeparator className="free-opportunity-assessment-page__benefits-wave-separator" />
	</Container>
);

export default Benefits;
