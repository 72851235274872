import React from 'react';

import './List.scss';

const List = ({ items }) => (
	<ul className="other-pages__body-text single-career-template__list">
		{items.map((item, index) => (
			<li key={index} className="single-career-template__list-item">
				{item}
			</li>
		))}
	</ul>
);

export default List;
