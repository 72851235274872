import React from 'react';

import { locations } from '../../../../../../Common/Constants';
import { InternalLink } from '../../../../../../Common/Components';
import { FooterMenuItem } from '../';

const FooterMenuFreeAssessments = ({ as: As, ...props }) => (
	<As name="Get Started" {...props}>
		<FooterMenuItem>
			<InternalLink to={locations.freeOpportunityAssessment}>Get started</InternalLink>
		</FooterMenuItem>
	</As>
);

export default FooterMenuFreeAssessments;
