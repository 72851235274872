import React from 'react';

import { Container, Responsive } from '../../../Common/Components';
import ApplyButton from './ApplyButton';

import './Hero.scss';

const Hero = ({ title, applyLink }) => (
	<Container as="section" className="single-career-template__hero" isCentered isHalfScreen>
		<Container hasPadding hasMaxWidth isCentered>
			<h2 className="other-pages__title single-career-template__title">
				{title}
			</h2>
			<ApplyButton link={ applyLink } />
		</Container>
	</Container>
);

export default Hero;
