import React from 'react';
import { MenuItem } from 'semantic-ui-react';

import { locations } from '../../../../../../../../Common/Constants';
import { InternalLink } from '../../../../../../../../Common/Components';

const SubmenuSolutions = ({ as: As, ...props }) => (
	<As {...props}>
		<MenuItem>
			<InternalLink to={locations.solutionsForShippers}>Shippers</InternalLink>
		</MenuItem>
		<MenuItem>
			<InternalLink to={locations.solutionsForCarriers}>Carriers</InternalLink>
		</MenuItem>
		<MenuItem>
			<InternalLink to={locations.solutionsForBrokers}>Brokers</InternalLink>
		</MenuItem>
	</As>
);

export default SubmenuSolutions;
