import React from 'react';

import { InternalLink, Card } from '../../../Common/Components';

import './_Career.scss';

const lastSpaceBeforeCareerDescription = new RegExp(/\s(?=\()/);

const separateCareerDescription = (text) => {
	const [title, description] = text.split(lastSpaceBeforeCareerDescription);

	return (
		<span>
			{title}
			<br />
			{description}
		</span>
	);
};

const Career = ({ title, office, link }) => (
	<InternalLink to={link}>
		<Card as="article" className="careers-page__career" isClickable>
			<section className="careers-page__career-title">
				{separateCareerDescription(title)}
			</section>
			<section className="careers-page__career-separator" />
			<section className="careers-page__career-office">
				{office}
			</section>
		</Card>
	</InternalLink>
);

export default Career;
