import React from 'react';
import classNames from 'classnames';

import { withDeviceType } from '.';

import './WaveSeparator.scss';

const getImageSize = (deviceType) => {
	if (deviceType.isMobile) {
		return 768;
	}

	if (deviceType.isTablet) {
		return 1920;
	}

	return 2560;
};

const getImageLink = (deviceType) => {
	const imageSize = getImageSize(deviceType);

	return `/images/backgrounds/${imageSize}/wave.png`;
}

const WaveSeparator = ({ isUpsideDown, deviceType, className }) => (
	<div
		className={classNames('wave-separator', {
			'wave-separator--upside-down': isUpsideDown,
		}, className)}
	>
		<img
			className="wave-separator__image"
			src={getImageLink(deviceType)}
			alt="wave-separator"
		/>
	</div>
);

export default withDeviceType(WaveSeparator);
