import React from 'react';
import { Grid, BackButton, Icons } from '.';

import './FormSuccessMessage.scss'

const FormSuccessMessage = ({ message, backToLocation }) => (
    <Grid>
        <Grid.Column width={12} className="form_success__message-wrapper">
            <Icons.SuccessBagSvg />
        </Grid.Column>
        <Grid.Column width={12} className="form_success__message-wrapper">
            <div className="form_success__message">
                {message}
            </div>
        </Grid.Column>
        <Grid.Column width={12} className="form_success__message-wrapper">
                <BackButton text='Back to homepage' to={backToLocation} />
        </Grid.Column>
    </Grid>
);

export default FormSuccessMessage;