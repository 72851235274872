import React from 'react';
import classNames from 'classnames';
import { Grid as SemanticUiGrid } from 'semantic-ui-react';

import './Grid.scss';

class Grid extends React.PureComponent {
	static Row = SemanticUiGrid.Row;
	static Column = SemanticUiGrid.Column;

	render() {
		const {
			hasPadding = true,
			isStreched = false,
			isMaxWidth = false,
			...props
		} = this.props;

		return (
			<SemanticUiGrid
				className={classNames('le-grid', {
					'le-grid--without-padding': !hasPadding,
					'le-grid--streched': isStreched,
					'le-grid--max-width': isMaxWidth,
				})}
				{...props}
			/>
		);
	}
}

export default Grid;
