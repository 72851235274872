import React from 'react';

import { Container, Grid, Markdown, withDeviceType, GreenDotHeading } from '../../../Common/Components';

import './Content.scss';

const headingRenderer = (props) => {

	if (props.level === 2) {
		return <h2>{props.children}</h2>;
	}

	return <GreenDotHeading {...props} />;
};

const Content = ({ markdownSrc }) => (
	<div className="legal-page__content">
		<Container as="section" className="legal-page__container" hasMaxWidth hasPadding >
			<Grid>
				<Grid.Column mobile={12} tablet={12} computer={12}>
					<Markdown
						className="legal-page__markdown"
						source={markdownSrc}
						renderers={{
							heading: (props) => headingRenderer(props)
						}}
					/>
				</Grid.Column>
			</Grid>
		</Container>
	</div>
);

export default withDeviceType(Content);
