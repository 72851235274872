import React from 'react';

import { Container, Grid, GreenDotHeading, DeorphanedText, WaveSeparator } from '../../../Common/Components';

import './_Screenshots.scss';

const Screenshots = () => (
	<section className="landing-page__screenshots">
		<Container hasPadding hasMaxWidth>
			<Grid>
				<Grid.Row verticalAlign="middle">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="landing-page__h3 landing-page__screenshot-title">
							One platform to manage everything
						</GreenDotHeading>
						<div className="landing-page__body-text">
							<DeorphanedText>
								Leaf Adapt is an end-to-end solution that provides continuous, actionable 
								guidance for contracting transportation based on changing business needs 
								and market conditions. Plan, manage, and execute Leaf Flex forward contracts 
								using Adapt to guarantee transportation capacity and lock in savings. If needs 
								change, use the Leaf Network to access additional capacity and demand, 
								adjusting plans to meet the new reality.
							</DeorphanedText>
						</div>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="landing-page__screenshot-image"
							src="/images/screenshots/One_platform_to_manage_everything.png"
							alt="one platform to manage everything"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row verticalAlign="middle" reversed="computer">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="landing-page__h3 landing-page__screenshot-title landing-page__screenshot-title--analytics">
							Smart analytics, out of the box
						</GreenDotHeading>
						<div className="landing-page__body-text">
							<DeorphanedText>
								Leaf's smart analytics engine provides
								insights into future rate and capacity
								developments. It automatically
								proposes the best-fit opportunities
								based on your own network as well as
								market trends, thus allowing you to make better
								contracting decisions.
							</DeorphanedText>
						</div>
					</Grid.Column>
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="computer" />
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="landing-page__screenshot-image landing-page__screenshot-image--right"
							src="/images/screenshots/Smart_analytics_out_of_box.png"
							alt="smart analytics out of box"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</section>
);

export default Screenshots;
