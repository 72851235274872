import React from 'react';
import classNames from 'classnames';

import { Icon, IconSizes } from '../../../../../../Common/Components';

import './FooterMenu.scss';
import './FooterMenuCollapsible.scss';

class FooterMenuCollapsible extends React.PureComponent {
	render() {
		const { name, openedSubmenu, handleToggleSubmenuClick, children } = this.props;
		const isSubmenuOpened = openedSubmenu === name;

		return (
			<div
				className={classNames('footer-menu', {
					'footer-menu--collapsible': true,
					'footer-menu--opened': isSubmenuOpened,
				})}
			>
				<button
					className="footer-menu__title footer-menu__control-button"
					onClick={() => handleToggleSubmenuClick(name)}
				>
					{name}
					<Icon
						className="icon"
						name="chevron-down"
						size={IconSizes.sm}
						isUpsideDown={isSubmenuOpened}
					/>
				</button>
				<ul className="footer-menu__items">
					{children}
				</ul>
			</div>
		);
	}
}

export default FooterMenuCollapsible;
