import React from 'react';

import { Layout } from '../../Features';
import { provideDeviceType } from '../../Common/Components';
import { Hero, Message, Employees, Collaborate } from './Components/_index.js';

import './_AboutUsPage.scss';

const AboutUsPage = () => (
	<Layout className="about-us-page">
		<Hero />
		<Message />
		<Employees />
		<Collaborate />
	</Layout>
);

export default provideDeviceType(AboutUsPage);
