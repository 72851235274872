import React from 'react';

import { Icon, IconSizes } from '../../../../../../../Common/Components';

import './NavMenuMobileControlButton.scss';

const NavMenuMobileControlButton = ({ isMenuOpened, onClick }) => (
	<Icon
		className="nav-menu-mobile__control-button"
		name={isMenuOpened ? 'close' : 'menu'}
		size={IconSizes.lg}
		onClick={onClick}
	/>
);

export default NavMenuMobileControlButton;
