import React from 'react'

import { provideDeviceType } from '../../Common/Components';
import { Layout, ContactSection } from '../../Features';
import { Hero, Cards, Screenshots, Testimonials, InNews } from './Components/_index';

import './_LandingPage.scss';

const LandingPage = () => (
  <Layout className="landing-page">
    <Hero />
    <Cards />
    <Screenshots />
    <Testimonials />
    <InNews />
    <ContactSection />
  </Layout>
);

export default provideDeviceType(LandingPage);
