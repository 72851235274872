import React from 'react';

import { Container, DeorphanedText, Grid } from '../../../Common/Components';
import QuestionMarkTitle from './_QuestionMarkTitle';

const DetailsFooter = () => (
	<Container isCentered hasMaxWidth hasPadding>
		<Grid textAlign="left">
			<Grid.Column mobile={12} tablet={12} computer={5}>
				<QuestionMarkTitle>What will you do with my data?</QuestionMarkTitle>
				<p className="other-pages__body-text details-footer__text">
					<DeorphanedText>
						We will use your data to programmatically identify and share relevant contracting opportunities with you.
						In order to find opportunities such as round trips and/or continuous moves across data sets from different parties,
						our analytics engine uses aggregated and anonymized information which cannot reasonably be used to identify you.
						We will never reveal shipment-level, confidential information such exact origins and destinations, company names,
						or rates to other parties, except for the information you decide to share with parties when entering an
						LE Forward Agreement on our platform. For more information, see our Privacy Policy.
					</DeorphanedText>
				</p>
			</Grid.Column>
			<Grid.Column computer={2} />
			<Grid.Column mobile={12} tablet={12} computer={5}>
				<QuestionMarkTitle>How are my data protected?</QuestionMarkTitle>
				<p className="other-pages__body-text details-footer__text">
					<DeorphanedText>
						We value your privacy. Communication to and from our servers is encrypted using a secure HTTPS connection.
						We take measures to protect our servers from outside intrusion and help protect
						information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.
						For more information, we refer to our Privacy Policy.
					</DeorphanedText>
				</p>
			</Grid.Column>
		</Grid>
	</Container>
);

export default DetailsFooter;
