import React from 'react';
import classNames from 'classnames';

import { Card, Grid, DeorphanedText, withDeviceType } from '../../../Common/Components';

import './_Employee.scss';

const defaultEmployeeImgSrc = 'Unknown.png';

const Employee = ({ imgSrc = defaultEmployeeImgSrc, name, position, bio, deviceType }) => (
	<Card as="article" className="about-us-page__employee">
		<section className="about-us-page__employee-id">
			<Grid hasPadding={false} verticalAlign="middle">
				<Grid.Column mobile={12} tablet={4} computer={4} widescreen={4} textAlign={deviceType.isMobile ? 'center' : ''} >
					<div className="about-us-page__employee-image-wrapper">
						<img
							className="about-us-page__employee-image"
							src={`/images/team/${imgSrc}`}
							alt="employee"
						/>
					</div>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={8} computer={8} widescreen={8} textAlign={deviceType.isMobile ? 'center' : 'left'} >
					<div className="about-us-page__employee-info">
						<div className="about-us-page__employee-name">
							{name}
						</div>
						<div className="about-us-page__employee-position">
							{position}
						</div>
					</div>
				</Grid.Column>
			</Grid>
		</section>
		<section className="about-us-page__employee-bio">
			<p className={classNames({
				'about-us-page__employee-bio-text': true,
				'about-us-page__employee-bio-text--centered': deviceType.isMobile,
				})}
			>
				<DeorphanedText>
					{bio}
				</DeorphanedText>
			</p>
		</section>
	</Card>
);

export default withDeviceType(Employee);
