import React from 'react';

import { Container, GreenDotHeading } from '../../../Common/Components';

import './Segment.scss';

const Segment = ({ title, children }) => (
	<Container as="article" className="single-career-template__segment">
		<GreenDotHeading as="h3" className="other-pages__h3 single-career-template__segment-title">
			{title}
		</GreenDotHeading>
		{children}
	</Container>
);

export default Segment;
