import React from 'react';

import { locations } from '../../../../../Common/Constants';
import { InternalLink, Logo, LogoSizes, withDeviceType } from '../../../../../Common/Components';

const getLogoSize = (deviceType) => {
	if (deviceType.isMobile) {
		return LogoSizes.base;
	}

	if (deviceType.isTablet) {
		return LogoSizes.base;
	}

	return LogoSizes.md;
}

const HeaderLogo = ({ deviceType }) => (
	<InternalLink className="header__logo" to={locations.index}>
		<Logo size={getLogoSize(deviceType)} />
	</InternalLink>
)

export default withDeviceType(HeaderLogo);
