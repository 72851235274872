import React from 'react';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

import './Button.scss';

export default ({ className, isSmall, isHighlighted, hasLargePadding, isDisabled, children, ...props }) => (
	<Button
		className={classNames('le-button', className, {
			'le-button--small': isSmall,
			'le-button--highlighted': isHighlighted,
			'le-button--padding-large': hasLargePadding,
		})}
		disabled={isDisabled}
		{...props}
	>
		{children}
	</Button>
);
