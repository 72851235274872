import React from 'react';

import './FooterMenu.scss';

const FooterMenu = ({ name, children }) => (
	<div className="footer-menu">
		<h3 className="footer-menu__title">
			{name}
		</h3>
		<ul className="footer-menu__items">
			{children}
		</ul>
	</div>
);

export default FooterMenu;
