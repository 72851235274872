import React from 'react';

import { DeviceTypeContext } from '../Contexts';

export default WrappedComponent =>
	class withDeviceType extends React.Component {
		render() {
			return (
				<DeviceTypeContext.Consumer>
					{deviceType =>
						<WrappedComponent
							{...this.props}
							deviceType={deviceType}
						/>
					}
				</DeviceTypeContext.Consumer>
			);
		}
	}
