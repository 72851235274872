import React from 'react';

import { Container, Grid, GreenDotHeading, Responsive } from '../../../Common/Components';

import './_Screenshots.scss';

const Screenshots = () => (
	<section className="free-opportunity-assessment-page__screenshots">
		<Container hasPadding hasMaxWidth>
			<Grid>
				<Grid.Row className="free-opportunity-assessment-page__screenshot" verticalAlign="middle">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 free-opportunity-assessment-page__screenshot-subtitle" hasThreeDots>
							Opportunities from your data
						</GreenDotHeading>
						<div className="other-pages__body-text free-opportunity-assessment-page__screenshot-text">
							LE suggests contracting opportunities from within <Responsive computer="br" />
							your network to improve cost and service <Responsive computer="br" />
							predictability.
						</div>
					</Grid.Column>
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="free-opportunity-assessment-page__screenshot-image--right"
							src="/images/screenshots/Free_Assessment_Chart.png"
							alt="free assessment chart"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="free-opportunity-assessment-page__screenshot" verticalAlign="middle" reversed="computer">
					<Grid.Column mobile={12} tablet={11} computer={4}>
						<GreenDotHeading as='h3' className="other-pages__h3 free-opportunity-assessment-page__screenshot-subtitle">
							... and from combining your data with others'
						</GreenDotHeading>
						<div className="other-pages__body-text free-opportunity-assessment-page__screenshot-text">
							By overlaying your network with the vast amounts of <Responsive computer="br" />
							other demand data on our platform, LE identifies <Responsive computer="br" />
							opportunities attractive to carriers and helps you <Responsive computer="br" />
							execute binding forward contracts to deliver <Responsive computer="br" />
							significant cost savings.
						</div>
					</Grid.Column>
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="tablet" />
					<Grid.Column width={1} only="computer" />
					<Grid.Column mobile={12} tablet={11} computer={7} floated="right">
						<img
							className="free-opportunity-assessment-page__screenshot-image free-opportunity-assessment-page__screenshot-image--right"
							src="/images/screenshots/Free_Assessment_Map.png"
							alt="free assessment map"
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</section>
);

export default Screenshots;
