import React from 'react';
import classNames from 'classnames';

const TwitterSvg = ({ className }) => (
    <svg className={classNames(className)} width="26px" height="22px" viewBox="0 0 26 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="icons" transform="translate(-327.000000, -111.000000)" stroke="#FFFFFF" strokeWidth="2">
                <path d="M352,112.011196 C350.955329,112.74808 349.798663,113.311678 348.574545,113.680286 C347.228756,112.132873 345.06004,111.590229 343.144089,112.321508 C341.228138,113.052787 339.972509,114.902433 340,116.953014 L340,118.043923 C336.10618,118.144889 332.420391,116.289709 330.181818,113.102105 C330.181818,113.102105 325.818182,122.920286 335.636364,127.283923 C333.389666,128.808977 330.713262,129.573664 328,129.465741 C337.818182,134.920286 349.818182,129.465741 349.818182,116.920286 C349.817177,116.616418 349.787961,116.313298 349.730909,116.014832 C350.844294,114.916824 351.629994,113.530519 352,112.011196 Z" id="Twitter"></path>
            </g>
        </g>
    </svg>
);

export default TwitterSvg;
