import React from 'react';

import './Content.scss';

const Content = ({ children }) => (
	<div className="content">
		{children}
	</div>
);

export default Content;
