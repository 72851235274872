import React from 'react';

import { Container, Grid, DeorphanedText, } from '../../../Common/Components';

import './_Message.scss';

const Message = () => (
	<Container as="section" className="why-leaf-page__message" hasMaxWidth hasPadding>
		<Grid textAlign="center">
			<Grid.Column mobile={12} tablet={10} computer={8}>
				<p className="other-pages__body-text why-leaf-page__message-text">
					<DeorphanedText>
						We give transportation buyers and sellers the tools to manage
						freight with unprecedented reliability. Leaf technology and forward
						contracting capabilities combine the benefits of a dedicated
						fleet — guaranteed capacity, service, and lower costs — with the flexibility
						to dial in capacity as needed. Join the Leaf Network and contract for
						days, weeks, or months at a time to meet and adapt your
						business needs as they change throughout the year.
					</DeorphanedText>
				</p>
			</Grid.Column>
		</Grid>
	</Container>
);

export default Message;
