import React from 'react';

import { locations } from '../../../Common/Constants';
import { Container, Grid, Icons, WaveSeparator, GreenDotHeading } from '../../../Common/Components';
import Benefit from './_Benefit';

import './_Benefits.scss';

const Benefits = () => (
	<Container as="section" className="free-opportunity-assessment-page__benefits">
		<Container hasMaxWidth hasPadding>
			<Grid textAlign="center">
				<Grid.Column mobile={12}>
					<GreenDotHeading as='h2' className="other-pages__h2 free-opportunity-assessment-page__benefits-subtitle">
						It couldn’t be simpler
					</GreenDotHeading>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.ServiceSvg}
						text="We’ll analyze your network data and identify opportunities for securing capacity and cost savings."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.DataChartSvg}
						text="The Leaf platform gives you the tools to build and manage a transportation plan that adapts to your needs."
					/>
				</Grid.Column>
				<Grid.Column mobile={12} tablet={12} computer={4}>
					<Benefit
						icon={Icons.RatesSvg}
						text="Tap into our network for planned and scheduled loads that save shippers time and money, and allow carriers to keep their trucks running full."
					/>
				</Grid.Column>
			</Grid>
		</Container>
		<WaveSeparator className="free-opportunity-assessment-page__benefits-wave-separator" />
	</Container>
);

export default Benefits;
