import React from 'react';

import { Container, withDeviceType } from '../../../Common/Components';

import './_Video.scss';

class Video extends React.Component {
	getDangerouslySetInnerHTML = () => ({ __html: this.props.iframe });

	render() {
		return (
			<Container isCentered hasPadding={!this.props.deviceType.isMobile} className="landing-page__video">
				<div className="landing-page__video-container">
					<div className="landing-page__video-iframe" dangerouslySetInnerHTML={ this.getDangerouslySetInnerHTML() } />
				</div>
			</Container>
		);
	}
}

export default withDeviceType(Video);
