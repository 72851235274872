import React from 'react';

import TestimonialsSlider from './_TestimonialsSlider';

import './_Testimonials.scss';

const Testimonials = () => (
	<section className="landing-page__wave-background">
		<TestimonialsSlider />
	</section>
);

export default Testimonials;
