import React from 'react';
import classNames from 'classnames';

import { Container, withDeviceType } from '../../../../Common/Components';
import { HeaderLogo, NavMenu } from './Components';

import './Header.scss';

class Header extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			isFixed: false,
		};

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop;

		this.setState({
			isFixed: windowScrollTop > 50,
		});
	}

	render() {
		const { hasNavigation, deviceType } = this.props;
		const { isFixed } = this.state;

		return (
			<div className={classNames('header-wrapper', {
				'header-wrapper--fixed': isFixed,
			})}
			>
				<Container className={classNames('header', { 'header--mobile': deviceType.isMobile || deviceType.isTablet })}
						   as="header"
						   hasPadding
						   hasMaxWidth>
					<HeaderLogo  />
					{hasNavigation && (
						<NavMenu />
					)}
				</Container>
			</div>
		);
	}
}

export default withDeviceType(Header);
