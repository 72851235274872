import React from 'react';

const initialDeviceType = {
	isMobile: false,
	isTablet: false,
	isDesktop: false,
};

const DeviceTypeContext = React.createContext(initialDeviceType);

export default DeviceTypeContext;
