import React from 'react';
import Slider from 'react-slick';

import TestimonialsSliderCard from './_TestimonialsSliderCard';

import './_TestimonialsSlider.scss';

const sliderSettings = {
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false,
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const TestimonialsSlider = () => (
	<Slider
		className="testimonials-slider"
		{...sliderSettings}
	>
		<TestimonialsSliderCard
			name="Loren Foster"
			position="Senior Director of OTR and Dedicated Transportation at AB Inbev"
			imageSrc="/images/testimonials/ABInBev.png"
			text="We’ve been working with Leaf since 2018, enhancing service to our customers and increasing predictability for our carrier partners. Using Leaf as a “logic layer”, we plan to further automate transportation decision making, systematically reducing manual effort and eliminating empty miles across our network as we work towards our goal of a carbon-neutral supply chain."
		/>
		<TestimonialsSliderCard
			name="Siva Narayanan"
			position="Director Global Supply Chain, Solvay Technology Solutions"
			imageSrc="/images/testimonials/Solvay.png"
			text="Leaf is helping us bring more predictability to our transportation operations, which first and foremost benefits our customers. Our work together has helped us improve supply chain reliability, and the optimum use of our storage assets."
		/>
	</Slider>
);

export default TestimonialsSlider;
